<template>
  <div>
    <div class="header pt-lg-5">
      <b-container>
        <button class="btn btn-primary btn-sm" @click="goBack">
          <i class="ni ni-bold-left"></i> KEMBALI
        </button>
      </b-container>
    </div>

    <b-container>
      <div class="header-body text-center mb-7">
        <b-row class="justify-content-center">
          <img
            src="../../../../../public/img/brand/img-logo-color.png"
            alt="klhk"
            class="mb-2"
          />
          <b-col md="12">
            <h2 class="text-black">
              PENDAFTARAN SISTEM PEMANTAUAN KUALITAS AIR LIMBAH SECARA TERUS
              MENERUS DAN DALAM JARINGAN (SPARING) BAGI PENANGGUNG JAWAB USAHA
              DAN/ATAU KEGIATAN
            </h2>
            <p class="text-lead text-black">
              Pendaftaran adalah fasilitas untuk pencatatan secara ONLINE dan
              validasi bagi penanggung jawab usaha dan/atau kegiatan yang
              bertujuan untuk mendapatkan USER NAME, PASSWORD, UID (User
              Identification).
            </p>
          </b-col>
        </b-row>
        <button class="btn btn-primary" @click="showAnnoncementModal = true">
          LIHAT MEKANISME
        </button>
      </div>
    </b-container>

    <!-- Loading Screen -->
    <Modal :show="isLoading" modalContentClasses="popup-modal-backgrounds" modalClasses="modal-dialog-scrollable"
      size="lg" style="background-color: rgba(0, 0, 0, 0.8)">
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4">
            <div class="row">
              <div class="col-lg-12 text-center" style="position: relative">
                <img src="img/brand/img-logo-color.png" alt="" />
                <img src="/img/brand/klhk.png" alt="" style="
                    position: absolute;
                    right: 0;
                    top: -200px;
                    opacity: 0.2;
                  " />
                <h1>Sedang Proses...</h1>
                <h5>Harap tunggu beberapa saat</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Modal Untuk Menampilkan Lihat Mekanisme -->
    <Modal
      :show="showAnnoncementModal"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showAnnoncementModal = false"
      size="xl"
    >
      <template v-slot:header>
        <div class="mdl-header d-flex">
          <img
            src="../../../../../public/img/brand/img-logo-color.png"
            alt="klhk"
            class="mb-2"
          />
          <h3 class="ml-3">
            Kementerian <br />
            Lingkungan Hidup dan Kehutanan <br />
            Republik Indonesia
          </h3>
        </div>
      </template>
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden mdl-contentpopups"
        >
          <div class="p-4" style="overflow-y: scroll; height: 450px">
            <h2 class="text-justify"></h2>
            <h2 class="text-justify">
              PENDAFTARAN SISTEM PEMANTAUAN KUALITAS AIR LIMBAH SECARA TERUS
              MENERUS DAN DALAM JARINGAN (SPARING) BAGI PENANGGUNG JAWAB USAHA
              DAN/ATAU KEGIATAN
            </h2>
            <p class="mb-2 text-justify">
              Pendaftaran adalah fasilitas untuk pencatatan secara ONLINE dan
              validasi bagi penanggung jawab usaha dan/atau kegiatan yang
              bertujuan untuk mendapatkan USER NAME, PASSWORD, UID (User
              Identification).
              <br />Mekanisme pendaftaran yaitu:
            </p>
            <ol>
              <li>
                Pendaftaran WAJIB dilakukan oleh penanggung jawab usaha dan/atau
                kegiatan (industri), tidak diperbolehkan bagi penyedia peralatan
                & jasa alat sensor pemantauan online.
              </li>
              <li>
                Penanggung jawab usaha dan/atau kegiatan WAJIB melaksanakan
                pendaftaran dan mengisi semua form serta bukti.
              </li>
              <li>
                Pengisian form pendaftaran dan bukti yaitu:
                <ol type="a">
                  <li>Pengisian umum</li>
                  <li>
                    Pengisian dan penyampaian bukti persyaratan identifikasi
                    sumber pencemar
                  </li>
                  <li>Pengisian dan penyampaian bukti persyaratan teknis</li>
                </ol>
              </li>
              <li>
                Hasil jawaban dari permohonan pendaftaran SPARING melalui fitur
                pendaftaran paling lambat dikirim 14 hari kerja setelah
                dikirimkan oleh penanggung jawab usaha dan/atau kegiatan.
              </li>
              <li>
                USER NAME, PASSWORD, UID (User Identification) berupa TANDA
                TERIMA ELEKTRONIK akan dikirimkan ke alamat email yang
                didaftarkan pada form pendaftaran melalui email
                sparing.menlhk@gmail.com jika seluruh isian lengkap dan
                dinyatakan valid oleh Admin SPARING KLHK.
              </li>
              <li>
                Bukti dan data dinyatakan valid melalui bukti persyaratan
                identifikasi sumber pencemar dan persyaratan teknis SPARING
                sesuai dengan Peraturan Menteri Lingkungan Hidup Nomor
                P.93/MENLHK/SETJEN/KUM.1/8/2018 tentang Pemantauan Kualitas Air
                Limbah Secara Terus Menerus Dan Dalam Jaringan Bagi Usaha
                Dan/Atau Kegiatan jo Peraturan Menteri Lingkungan Hidup Nomor
                P.80/MENLHK/SETJEN/KUM.1/10/2019.
              </li>
              <li>
                Jika isian tidak lengkap dan terdapat bukti yang tidak valid
                maka Admin SPARING KLHK akan menyampaikan melalui email yang
                terdaftar. Penanggung jawab usaha dan/atau kegiatan WAJIB
                melengkapi sesuai dengan hasil evaluasi.
              </li>
              <li>
                Kebijakan pengunduran waktu pemasangan dan pengoperasian SPARING
                sesuai Surat Edaran Menteri Lingkungan Hidup dan Kehutanan Nomor
                SE.5/Menlhk/Setjen/KUM.1/6/2020 tidak berlaku lagi.
              </li>
            </ol>

            <br />
            <span>
              Tim SPARING<br />
              Direktorat Pengendalian Pencemaran air<br />
              JL.DI Panjaitan Kav. 24 Kebon Nanas Jakarta Timur<br />
              Gedung B lantai 5<br />
              Email: sparing.menlhk@gmail.com<br />
              Fathia Rizki 0813 10837646 ( hanya melayani via pesan WA/SMS, jam
              pelayanan hari kerja 08.00-15.00 WIB)<br />
            </span>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-primary border-0"
              @click="showAnnoncementModal = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Modal untuk Tambah Surat Izin -->
    <Modal
      :show="showFormModal"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      size="xl"
    >
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden mdl-contentpopups"
        >
          <div>
            <div class="p-3">
              <h2 class="text-center">Dokumen Perizinan</h2>
              <div class="form-group row mt-4">
                <label class="col-lg-3 col-form-label form-control-label" >Izin Pembuangan Air Limbah ke MediaAir</label>
                <div class="col-lg-9">
                  <base-input
                    name="Izin Pembuangan Air Limbah ke Media Air"
                  >
                    <file-input
                      accept="application/pdf"
                      ref="sparing_air_limbah_file"
                      @change="uploadPermitFileBlob(form.teknis,'sparing_air_limbah_file')
                      "
                    ></file-input>
                  </base-input>
                </div>
              </div>

                  <!-- Pejabat penerbit izin -->
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label">Pejabat Penerbit Izin</label>
                    <div class="col-lg-9">
                      <base-input
                        name="Pejabat Penerbit Izin"
                        rules="required"
                        v-model="form.teknis.permitPerson"
                      ></base-input>
                      <small class="d-block mt--3 text-justify">
                        Contoh Kepala Dinas Modal dan Pelayanan Terpadu Satu Pintu
                        (DPM-PTSP) Provinsi DKI Jakarta
                      </small>
                    </div>
                  </div>
    
                  <!-- Nomor Izin Pembuangan Air Limbah -->
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label mt--2">Nomor Izin Pembuangan Air Limbah (Definitif)</label>
                    <div class="col-lg-9">
                      <base-input
                        name="Nomor Izin Pembuangan Air Limbah (Definitif)"
                        rules="required"
                        v-model="form.teknis.permitNumber"
                      ></base-input>
                      <small class="d-block mt--3 text-justify">
                        Diisi nomor izin pembuangan air limbah, bukan surat
                        pemenuhan komitmen. Contoh : Nomor :
                        503/KEP.B75BFFFC-PTSP/2019.
                      </small>
                    </div>
                  </div>
    
                  <!-- Tanggal Izin Terbit -->
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label">Tanggal Izin Terbit</label>
                    <div class="col-lg-9">
                      <base-input
                        class="m-0"
                        rules="required"
                        name="Tanggal Izin Terbit"
                      >
                        <flat-picker
                          name="Tanggal Izin Terbit"
                          rules="required"
                          slot-scope="{ focus, blur }"
                          @on-open="focus"
                          @on-close="blur"
                          :config="selects.configs.flatpickr"
                          class="form-control datepicker"
                          v-model="form.teknis.permitDatePublish"
                        ></flat-picker>
                      </base-input>
                    </div>
                  </div>
    
                  <!-- Berlaku -->
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label">Berlaku</label>
                    <div class="col-lg-9">
                      <base-input class="m-0" rules="required" name="Berlaku">
                        <flat-picker
                          name="Berlaku"
                          rules="required"
                          slot-scope="{ focus, blur }"
                          @on-open="focus"
                          @on-close="blur"
                          :config="selects.configs.flatpickr"
                          class="form-control datepicker"
                          v-model="form.teknis.permitDateExpire"
                        ></flat-picker>
                      </base-input>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col text-center">
                      <base-button class="bg-secondary border-0" @click="createPermissionLater()">+ Buat Dokumen</base-button>
                      <base-button class="bg-danger border-0" @click="showFormModal = false">Tutup</base-button>
                    </div>
                  </div>


            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Main -->
    <b-container>
      <div class="card">
        <div>
          <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
            <div>
              <div class="p-5">
                <div>
                  <div>
                    <div>
                      <div style="margin-bottom: 30px;display: flex;justify-content: space-between;">
                        <div style="display:flex;flex-direction: column;line-height:0">
                          <h3>Perubahan Data Sparing</h3> 
                          <span><small>Identifikasi Sumber Pencemar, Persyaratan Teknis, Logger, Sensor</small></span>
                        </div>
                        <!-- <base-button
                          size="md"
                          type="secondary"
                          @click="showFormTitikPenataanModal"
                        >
                          <i class="ni ni-archive-2"></i>Tambah titik penaatan
                        </base-button> -->
                      </div>
                      <button class="btn btn-primary" @click="changeUpdateSection('main')">Surat Izin</button>
                      <button class="btn btn-primary" @click="changeUpdateSection('station')">Titik Penaatan</button>
                      <button class="btn btn-primary" @click="changeUpdateSection('logger')">Logger</button>
                      <button class="btn btn-primary" @click="changeUpdateSection('sensor')">Sensor</button>

                      <!-- TABLE HERE -->
                      <!-- <template>
                        <div class="content">
                          <div>
                            <el-table
                              :data="newPayloadTmp"
                              row-key="namaTitikPenaatan"
                              header-row-class-name="thead-light"
                              @sort-change="sortChange"
                              @selection-change="selectionChange"
                            >
                              <el-table-column
                                min-width="180px"
                                label="Nama Titik Penaatan"
                              >
                                <div slot-scope="action">
                                  <b>{{ action.row.namaTitikPenaatan }}</b>
                                </div>
                              </el-table-column>

                              <el-table-column
                                min-width="180px"
                                label="Lintang"
                              >
                                <div slot-scope="action">
                                  <b>{{ action.row.lintang }}</b>
                                </div>
                              </el-table-column>

                              <el-table-column
                                min-width="180px"
                                label="Bujur"
                              >
                                <div slot-scope="action">
                                  <b>{{ action.row.bujur }}</b>
                                </div>
                              </el-table-column>

                             
                              <el-table-column min-width="180px" label="AKSI">
                                <div slot-scope="action">
                                  <base-button
                                    @click="populateUpdateStation(action.row)"
                                    type="warning"
                                    size="sm"
                                    icon
                                  >
                                    RUBAH
                                  </base-button>
                                  <base-button
                                    @click="willDeleteStation(action.row)"
                                    type="danger"
                                    size="sm"
                                    icon
                                  >
                                    HAPUS
                                  </base-button>
                                </div>
                              </el-table-column>
                            </el-table>
                          </div>
                        </div>
                      </template> -->
                      <!-- END OF TABLE HERE -->
                    </div>
                  </div>
                </div>

                <div v-if="updateSection == 'main'">
                  <hr/>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label">Nomor Surat Izin</label>
                      <div class="col-lg-9">
                        <base-input name="Permission Later">
                          <el-select v-model="permissionLaterFormExistingSelected">
                            <el-option
                              v-for="(item, index) in permissionLaterFormExisting"
                              :key="index"
                              :label="`${item.issuing_authority} NO ${item.no}`"
                              :value="item.id"
                            />
                          </el-select>
                        </base-input>
                        <small class="d-block mt--3 text-justify" style="color:blue;cursor:pointer" @click="showFormModal = true">
                          Belum punya surat izin ? Tambah Surat izin dengan klik link ini
                        </small>
                      </div>
                  </div>

                  <!-- Sumber air limbah a-->
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label">Sumber Air Limbah</label>
                    <div class="col-lg-9">
                      <base-input name="Sumber Air Limbah" v-model="form.teknis.wasteSource"></base-input>
                      <small class="d-block mt--3 text-justify">
                        Air Limbah proses produksi/air limbah utilitas/air limbah
                        domestik/air limbah pencucian atau pengolahan mineral/air
                        limbah penambangan mineral/air limbah pencucian atau
                        pengolahan batu bara/air limbah penambangan batu bara.
                      </small>
                    </div>
                  </div>
                  <button @click="finalSubmit(mainIdWillUpdate)" class="btn btn-primary">UBAH INFORMASI</button>
                </div>

                <div v-if="dataExistSensors.length > 0">
                  <div v-for="(item, index) in dataExistSensors" :key="index">
                      <hr/>
                      <!-- ======================================= STATION ======================================= -->
                      <div v-if="updateSection == 'station'">
                        <div class="form-group row">
                          <label class="col-lg-3 col-form-label form-control-label">Titik Penaatan {{ index + 1 }}</label>
                        </div>

                        <div @click="toggleAccordion('Logger', item.id, index)" class="accordion-header" style="cursor:pointer">
                          <button class="btn btn-secondary">Lihat Titik Penaatan</button>
                        </div>

                        <div class="col-lg-12 mt-3" :hidden="isHidden !== index">
                          <div class="mt-5">
                            <h3 class="text-center mb-3">Identifikasi Sumber Pencemar</h3>
                            <div>
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Nama Titik Penaatan</label>
                                <div class="col-lg-9">
                                  <base-input name="Nama Titik Penaatan" v-model="dataExistSensors[index].spot_name" :rules="{required: true}"/>
                                  <small class="d-block mt--3 text-justify">
                                    Nama titik penaatan yang akan didaftarkan
                                    untuk SPARING WAJIB sama dengan titik penaatan
                                    aktif pada SIMPEL PPA
                                  </small>
                                </div>
                              </div>
      
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Lintang</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Lintang"
                                    type="number"
                                    v-model="dataExistSensors[index].latitude"
                                    :rules="{required: true}"
                                  />
                                  <small class="d-block mt--3 text-justify">Contoh: -7.77751</small>
                                  <br />
                                  <small class="d-block mt--3 text-justify">Titik penaatan yang akan didaftarkan untuk SPARING.</small>
                                </div>
                              </div>
      
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Bujur</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Bujur"
                                    type="number"
                                    v-model="dataExistSensors[index].longitude"
                                    :rules="{required: true}"
                                  />
                                  <small class="d-block mt--3 text-justify">Contoh: 110.37804</small>
                                  <br />
                                  <small class="d-block mt--3 text-justify">Titik penaatan yang akan didaftarkan untuk SPARING.</small>
                                </div>
                              </div>
      
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Nama Badan Air Penerima Air Limbah</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Nama Badan Air Penerima Air Limbah"
                                    v-model="dataExistSensors[index].waste_receiver"
                                    :rules="{required: true}"
                                  />
                                </div>
                              </div>
      
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">DAS</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="DAS"
                                    v-model="dataExistSensors[index].das"
                                    :rules="{required: true}"
                                  />
                                </div>
                              </div>

                              <!-- Jenis teknologi pengolahan air limbah -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label mt--2">Jenis Teknologi Pengolahan AirLimbah</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Jenis Teknologi Pengolahan Air Limbah"
                                    v-model="dataExistSensors[index].processing_technique"
                                    :rules="{required: true}"
                                  />
                                  <small class="d-block mt--3 text-justify">
                                    Disesuaikan dengan Izin pembuangan air limbah atau izin
                                    lingkungan/AMDAL/UKL UPL/RKL RPL. Contoh: Aerob,
                                    Anaerob, dll.
                                  </small>
                                </div>
                              </div>
          
                              <!-- Jenis air limbah -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Jenis Air Limbah</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Jenis Air Limbah"
                                    v-model="dataExistSensors[index].waste_type"
                                    :rules="{required: true}"
                                  />
                                  <small class="d-block mt--3 text-justify">
                                    Contoh: air terproduksi, air limbah proses, air limbah
                                    lokasi penambangan, dll.
                                  </small>
                                </div>
                              </div>
          
                              <!-- Debit -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Debit</label>
                                <div class="col-lg-9 d-flex px-0">
                                  <div class="col-6" id="debit">
                                    <base-input
                                      name="debit"
                                      type="number"
                                      v-model="dataExistSensors[index].debit_value"
                                      :rules="{required: true}"
                                    />
                                    <small class="d-block mt--3 text-justify"
                                      >Debit Maksimum yang wajib di dalam izin</small
                                    >
                                  </div>
                                  <div class="col-6">
                                    <base-input name="Satuan debit" :rules="{required: true}">
                                      <el-select v-model="dataExistSensors[index].debit_unit">
                                        <el-option
                                          v-for="(item, index) in [
                                            'm/3 detik',
                                            'm/3 menit',
                                            'm/3 jam',
                                            'm/3 hari',
                                            'm3 bulan',
                                          ]"
                                          :key="index"
                                          :label="item"
                                          :value="item"
                                        />
                                      </el-select>
                                    </base-input>
                                    <small class="d-block mt--3 text-justify"
                                      >nama satuan</small
                                    >
                                  </div>
                                </div>
                              </div>
          
                              <!-- Kapasitas produksi sesuai izin -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Kapasitas Produksi Sesuai Izin</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Kapasitas Produksi Sesuai Izin"
                                    type="number"
                                    v-model="dataExistSensors[index].capacity_permit"
                                    :rules="{required: true}"
                                  />
                                  <small class="d-block mt--3 text-justify">Izin Lingkungan/AMDAL/UKL UPL/RKL RPL</small>
                                </div>
                              </div>
          
                              <!-- Kapasitas produksi senyatanya -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Kapasitas Produksi Senyatanya</label>
                                <div class="col-lg-9">
                                  <base-input
                                    name="Kapasitas Produksi Senyatanya"
                                    type="number"
                                    v-model="dataExistSensors[index].capacity_value"
                                    :rules="{required: true}"
                                  />
                                  <small class="d-block mt--3 text-justify">
                                    Pencatatan logbook bulanan selama 1 atau 2 tahun
                                    terakhir. Kapasitas produksi rata-rata bulanan.
                                  </small>
                                </div>
                              </div>
          
                              <!-- Frequensi pembuangan air limbah -->
                              <div class="form-group row">
                                <label class="col-lg-3 col-form-label form-control-label">Frekuensi Pembuangan Air Limbah</label>
                                <div class="col-lg-9">
                                  <base-input name="Per tahun" :rules="{required: true}">
                                    <el-select v-model="dataExistSensors[index].frequency.type">
                                      <el-option
                                        label="Terus Menerus/Kontinyu"
                                        value="Terus Menerus/Kontinyu"
                                      ></el-option>
                                      <el-option
                                        label="Tidak Rutin/Intermiten"
                                        value="Tidak Rutin/Intermiten"
                                      ></el-option>
                                    </el-select>
                                  </base-input>
                                </div>
                              </div>

                          </div>

                          <hr />
                        <h3 class="text-center mb-3">Persyaratan Teknis</h3>
      
                        <!-- Detail frekuensi pembuangan air limbah -->
                        <div
                          v-if="dataExistSensors[index].frequency.type === 'Terus Menerus/Kontinyu'"
                          class="card bg-white border-0"
                        >
                          <div class="card-header px-4">
                            Detail Frekuensi Pembuangan Air Limbah
                          </div>
                          <div class="card-body p-4">
                            <div class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label" >Per hari</label>
                              <div class="col-lg-9">
                                <base-input name="Per hari">
                                  <el-select v-model="dataExistSensors[index].frequency.jam">
                                    <el-option
                                      v-for="item in 24"
                                      :key="item + ' jam'"
                                      :label="item + ' jam'"
                                      :value="item"
                                    ></el-option>
                                  </el-select>
                                </base-input>
                              </div>
                            </div>
      
                            <div class="form-group row">
                              <label
                                class="col-lg-3 col-form-label form-control-label"
                                >Per minggu</label
                              >
                              <div class="col-lg-9">
                                <base-input name="Per minggu">
                                  <el-select v-model="dataExistSensors[index].frequency.hari">
                                    <el-option
                                      v-for="item in 7"
                                      :key="item + ' hari'"
                                      :label="item + ' hari'"
                                      :value="item"
                                    ></el-option>
                                  </el-select>
                                </base-input>
                              </div>
                            </div>
      
                            <div class="form-group row">
                              <label
                                class="col-lg-3 col-form-label form-control-label"
                                >Per tahun</label
                              >
                              <div class="col-lg-9">
                                <base-input name="Per tahun">
                                  <el-select v-model="dataExistSensors[index].frequency.bulan">
                                    <el-option
                                      v-for="item in 12"
                                      :key="item + ' bulan'"
                                      :label="item + ' bulan'"
                                      :value="item"
                                    ></el-option>
                                  </el-select>
                                </base-input>
                              </div>
                            </div>
                          </div>
                        </div>
      
                        <div
                          v-if="dataExistSensors[index].frequency.type === 'Tidak Rutin/Intermiten'" class="card bg-white border-0">
                          <div class="card-header px-4">Detail Frekuensi Pembuangan Air Limbah</div>
                          <div class="card-body p-4">
                            <!-- Latar belakang -->
                            <div class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Latar Belakang</label>
                              <div class="col-lg-9">
                                <html-editor
                                  v-model="dataExistSensors[index].frequency.latar_belakang"
                                ></html-editor>
                                <small class="d-block mt--3 text-justify">
                                  Penjelasan (deskripsi) dilakukan pembuangan air
                                  limbah secara intermiten
                                </small>
                              </div>
                            </div>
      
                            <!-- Detail engineering IPAL -->
                            <div class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Detail Engineering IPAL</label>
                              <div class="col-lg-9">
                                <html-editor
                                  v-model="dataExistSensors[index].frequency.detail_eng_ipal"
                                ></html-editor>
                              </div>
                            </div>
      
                            <!-- foto ipal -->
                            <div class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Foto IPAL</label>
                              <div class="col-lg-9">
                                <base-input name="Foto IPAL">
                                <file-input :disabled="uploading" accept="image/x-png, image/jpeg, application/pdf"
                                :ref="`ipal_file_id${index}`"
                                  @change="uploadFileBlob(dataExistSensors[index].frequency, 'ipal_file_id',index)"></file-input>
                                </base-input>
                                <small v-if="dataExistSensors[index].frequency.ipal_file_id" class="d-block mt-3 mb-2 text-justify">
                                  <a :href="getBlobsFile(dataExistSensors[index].frequency.ipal_file_id)" target="_blank"
                                    class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                </small>
                              </div>
                            </div>
      
                            <!-- perhari -->
                            <div class="form-group row">
                              <label
                                class="col-lg-3 col-form-label form-control-label"
                                >Per hari</label
                              >
                              <div class="col-lg-9">
                                <base-input name="Per hari">
                                  <el-select v-model="dataExistSensors[index].frequency.jam">
                                    <el-option
                                      v-for="item in 24"
                                      :key="item + ' jam'"
                                      :label="item + ' jam'"
                                      :value="item"
                                    ></el-option>
                                  </el-select>
                                </base-input>
                              </div>
                            </div>
      
                            <!-- perminggu -->
                            <div class="form-group row">
                              <label
                                class="col-lg-3 col-form-label form-control-label"
                                >Per minggu</label
                              >
                              <div class="col-lg-9">
                                <base-input name="Per minggu">
                                  <el-select v-model="dataExistSensors[index].frequency.hari">
                                    <el-option
                                      v-for="item in 7"
                                      :key="item + ' hari'"
                                      :label="item + ' hari'"
                                      :value="item"
                                    ></el-option>
                                  </el-select>
                                </base-input>
                              </div>
                            </div>
      
                            <!-- pertahun -->
                            <div class="form-group row">
                              <label
                                class="col-lg-3 col-form-label form-control-label"
                                >Per tahun</label
                              >
                              <div class="col-lg-9">
                                <base-input name="Per tahun">
                                  <el-select v-model="dataExistSensors[index].frequency.bulan">
                                    <el-option
                                      v-for="item in 12"
                                      :key="item + ' bulan'"
                                      :label="item + ' bulan'"
                                      :value="item"
                                    ></el-option>
                                  </el-select>
                                </base-input>
                              </div>
                            </div>
      
                            <!-- metode pengukuran debit -->
                            <div class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Metode Pengukuran Debit</label>
                              <div class="col-lg-9">
                                <html-editor v-model="dataExistSensors[index].frequency.metode_ukur"></html-editor>
                                <small class="d-block mt--3 text-justify">Metode pengukuran debit yang dilakukan secaramanual.</small>
                              </div>
                            </div>
      
                            <!-- Logbook pembuangan air -->
                            <div class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Logbook Pembuangan Air Limbah</label>
                              <div class="col-lg-9">
                                <base-input name="Logbook">
                                <file-input accept="application/pdf" 
                                  :ref="`logbook_file_id${index}`"
                                  @change="uploadFileBlob(dataExistSensors[index].frequency, 'logbook_file_id',index)"></file-input>
                                </base-input>
                                <small v-if="dataExistSensors[index].frequency.logbook_file_id" class="d-block mt-3 mb-2 text-justify">
                                  <a :href="getBlobsFile(dataExistSensors[index].frequency.logbook_file_id)" target="_blank"
                                    class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                </small>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Logger -->
                        <div>
                          <div>
                              <form>
                                <div class="form-group row">
                                  <label class="col-lg-3 col-form-label form-control-label">Lulus Uji Konektifitas</label>
                                  <div class="col-lg-9">
                                    <base-input name="Lulus Uji Konektifitas">
                                      <el-select v-model="dataExistSensors[index].tech_requirement.tipe_uji">
                                        <el-option
                                          label="Individu"
                                          value="Individu"
                                        ></el-option>
                                        <el-option
                                          label="Penyedia Barang dan Jasa Alat Sparing"
                                          value="Penyedia Barang dan Jasa Alat Sparing"
                                        ></el-option>
                                      </el-select>
                                    </base-input>
                                    <small class="d-block mt--3 text-justify">
                                      Individu dipilih bagi industri yang memasang dan
                                      mengoperasikan tanpa bekerja sama dengan
                                      penyedia barang/jasa alat sensor dan logger.
                                    </small>
                                  </div>
                                </div>
      
                                <!-- Nomor surat lulus uji konektifitas -->
                                <div v-if="dataExistSensors[index].tech_requirement.tipe_uji === 'Individu'" class="form-group row">
                                  <label class="col-lg-3 col-form-label form-control-label" >Nomor Surat Lulus Uji Konektivitas</label>
                                  <div class="col-lg-9">
                                    <base-input
                                      name="Brand"
                                      v-model="dataExistSensors[index].tech_requirement.no_surat"
                                    />
                                    <small class="d-block mt--3 text-justify">Nomor surat Lulus Uji Konektivitas diterbitkan olehKLHK</small>
                                  </div>
                                </div>
      
                                <!-- Surat keterangan lulus uji konektifitas -->
                                <div class="form-group row">
                                  <label class="col-lg-3 col-form-label form-control-label">Surat Keterangan Lulus Uji Konektivitas</label>
                                  <div class="col-lg-9">
                                    <base-input name="Lulus Uji Konektivitas">
                                      <file-input accept="application/pdf" 
                                      :ref="`lulus_uji_file_id${index}`"
                                        @change="uploadFileBlob(dataExistSensors[index].tech_requirement, 'lulus_uji_file_id',index)" />
                                    </base-input>
                                    <small v-if="dataExistSensors[index].tech_requirement.lulus_uji_file_id"
                                      class="d-block mt-3 mb-2 text-justify">
                                      <a :href="getBlobsFile(dataExistSensors[index].tech_requirement.lulus_uji_file_id)" target="_blank"
                                        class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                    </small>
                                  
                                    <small class="d-block  text-justify"
                                      >Wajib yang masih berlaku</small
                                    >
                                  </div>
                                </div>
      
                                <!-- Nama penyedia barang dan jasa -->
                                <div
                                  v-if="dataExistSensors[index].tech_requirement.tipe_uji ==='Penyedia Barang dan Jasa Alat Sparing'" class="form-group row">
                                  <label class="col-lg-3 col-form-label form-control-label">Nama Penyedia Barang dan Jasa</label>
                                  <div class="col-lg-9">
                                    <base-input
                                      name="Brand"
                                      v-model="dataExistSensors[index].tech_requirement.nama_penyedia"
                                    />
                                    <small class="d-block mt--3 text-justify"
                                      >Surat Keterangan Lulus Uji Konektivitas.</small
                                    >
                                  </div>
                                </div>
      
                                <!-- Kontrak kerja sama -->
                                <div v-if="dataExistSensors[index].tech_requirement.tipe_uji ==='Penyedia Barang dan Jasa Alat Sparing'" 
                                 class="form-group row">
                                  <label class="col-lg-3 col-form-label form-control-label">Kontrak Kerjasama</label>
                                  <div class="col-lg-9">
                                    <base-input name="Surat Keterangan Lulus Uji Konektivitas">
                                      <file-input accept="application/pdf" :ref="`kontak_kerjasama_file_id${index}`"
                                        @change="uploadFileBlob(dataExistSensors[index].tech_requirement,'kontak_kerjasama_file_id',index)"></file-input>
                                    </base-input>
                                    <small v-if="dataExistSensors[index].tech_requirement.kontak_kerjasama_file_id"
                                      class="d-block mt-3 mb-2 text-justify">
                                      <a :href="getBlobsFile(dataExistSensors[index].tech_requirement.kontak_kerjasama_file_id)"
                                        target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                    </small>
                                    
                                  </div>
                                </div>
      
                                <!-- Info Alat -->
                                <div v-if="dataExistSensors[index].tech_requirement.tipe_uji !== null" class="form-group row">
                                  <label class="col-lg-3 col-form-label form-control-label">Info Alat</label>
                                  <div class="col-lg-9">
                                    <base-input name="Per tahun" rules="required">
                                      <el-select v-model="dataExistSensors[index].tech_requirement.info_alat">
                                        <el-option
                                          label="Baru"
                                          value="Baru"
                                        ></el-option>
                                        <el-option
                                          label="Lama"
                                          value="Lama"
                                        ></el-option>
                                      </el-select>
                                    </base-input>
                                    <small class="d-block mt--3 text-justify">
                                      Alat lama adalah sensor yang sudah terpasang
                                      sebelum Permen LH 93/2018.
                                    </small>
                                  </div>
                                </div>
      
                                <div v-if="dataExistSensors[index].tech_requirement.tipe_uji !== null" class="form-group row">
                                  <label
                                    class="col-lg-3 col-form-label form-control-label"
                                    >Hasil Kalibrasi Saat Awal Pemasangan Alat</label
                                  >
                                  <div
                                    v-if="dataExistSensors[index].tech_requirement.tipe_uji !== null"
                                    class="col-lg-9"
                                  >
                                  <base-input name="Hasil Kalibrasi Saat Awal Pemasangan Alat">
                                  <file-input accept="application/pdf" 
                                  :ref="`kalibrasi_awal_file_id${index}`" @change="
                                      uploadFileBlob(dataExistSensors[index].tech_requirement, 'kalibrasi_awal_file_id',index)
                                      "></file-input>
                                  </base-input>
                                  <small v-if="dataExistSensors[index].tech_requirement.kalibrasi_awal_file_id"
                                    class="d-block mt-3 mb-2 text-justify">
                                    <a :href="getBlobsFile(dataExistSensors[index].tech_requirement.kalibrasi_awal_file_id)" target="_blank"
                                      class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                  </small>
                                 
                                    <small class="d-block text-justify"
                                      >Sertifikat hasil uji kalibrasi.</small
                                    >
                                  </div>
                                </div>
      
                                <!-- Hasil kalibrasi alat rutin -->
                                <div
                                  v-if="dataExistSensors[index].tech_requirement.tipe_uji !== null"
                                  class="form-group row"
                                >
                                  <label
                                    class="col-lg-3 col-form-label form-control-label"
                                    >Hasil Kalibrasi Alat Rutin</label
                                  >
                                  <div class="col-lg-9">
                                    <base-input name="Hasil Kalibrasi Alat Rutin">
                                  <file-input accept="application/pdf" 
                                  :ref="`kalibrasi_rutin_file_id${index}`"
                                  @change="
                                      uploadFileBlob(dataExistSensors[index].tech_requirement, 'kalibrasi_rutin_file_id',index)"></file-input>
                                  </base-input>
                                  <small v-if="dataExistSensors[index].tech_requirement.kalibrasi_rutin_file_id"
                                    class="d-block mt-3 mb-2 text-justify">
                                    <a :href="getBlobsFile(dataExistSensors[index].tech_requirement.kalibrasi_rutin_file_id)"
                                      target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                  </small>
                                  
                                    <small class="d-block  text-justify"
                                      >Sertifikat hasil uji kalibrasi.</small
                                    >
                                  </div>
                                </div>
      
                                <!-- Hasil uji kinerja dari labotarium yang ditunjuk oleh KLHK -->
                                <div
                                  v-if="dataExistSensors[index].tech_requirement.tipe_uji !== null"
                                  class="form-group row"
                                >
                                  <label
                                    class="col-lg-3 col-form-label form-control-label"
                                  >
                                    Hasil Uji Kinerja Dari Laboratorium Yang Ditunjuk
                                    Oleh KLHK
                                  </label>
                                  <div class="col-lg-9">
                                    <base-input name="Hasil Uji Kinerja Dari Laboratorium Yang Ditunjuk Oleh KLHK">
                                  <file-input accept="application/pdf" 
                                  :ref="`kinerja_file_id${index}`"
                                  @change="
                                    uploadFileBlob(dataExistSensors[index].tech_requirement, 'kinerja_file_id',index)
                                    "></file-input>
                                  </base-input>
                                  <small v-if="dataExistSensors[index].tech_requirement.kinerja_file_id"
                                    class="d-block mt-3 mb-2 text-justify">
                                    <a :href="getBlobsFile(dataExistSensors[index].tech_requirement.kinerja_file_id)" target="_blank"
                                      class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                  </small>
                                    <small class="d-block  text-justify">
                                      Uji kinerja alat wajib dilaksanakan di
                                      laboratorium yang ditunjuk oleh KLHK cq. Pusat
                                      Standarisasi Lingkungan dan Kehutanan.
                                      Laboratorium dimaksud adalah laboratorium yang
                                      melaksanakan pengujian kinerja alat pemantauan
                                      online air sesuai prosedur dalam petunjuk teknis
                                      Uji Kinerja Alat Pemantauan Online untuk Air
                                      mengacu kepada SNI ISO 15839:2003.
                                    </small>
                                  </div>
                                </div>
      
                                <!-- Surat dukungan dari brand sensor -->
                                <div
                                  v-if="dataExistSensors[index].tech_requirement.tipe_uji !== null"
                                  class="form-group row"
                                >
                                  <label
                                    class="col-lg-3 col-form-label form-control-label"
                                    >Surat Dukungan Dari Brand Sensor</label
                                  >
                                  <div class="col-lg-9">
                                    <base-input name="Surat Dukungan Dari Brand Sensor">
                                      <file-input accept="application/pdf" 
                                      :ref="`dukungan_brand_file_id${index}`"
                                       @change="
                                        uploadFileBlob(dataExistSensors[index].tech_requirement, 'dukungan_brand_file_id',index)
                                        "></file-input>
                                    </base-input>
                                    <small v-if="dataExistSensors[index].tech_requirement.dukungan_brand_file_id"
                                      class="d-block mt-3 mb-2 text-justify">
                                      <a :href="getBlobsFile(dataExistSensors[index].tech_requirement.dukungan_brand_file_id)" target="_blank"
                                        class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                    </small>
                                    
                                    <small class="d-block  text-justify"
                                      >Upload Surat Dukungan Dari Brand Sensor</small
                                    >
                                  </div>
                                </div>
      
                                <!-- Surat pernyataan -->
                                <div
                                  v-if="dataExistSensors[index].tech_requirement.tipe_uji !== null"
                                  class="form-group row"
                                >
                                  <label
                                    class="col-lg-3 col-form-label form-control-label"
                                    >Surat Pernyataan</label
                                  >
                                  <div class="col-lg-9">
                                    <base-input name="Surat Pernyataan dari Vendor Sensor">
                                  <file-input accept="application/pdf" 
                                  :ref="`pernyataan_vendor_file_id${index}`"
                                   @change="
                                    uploadFileBlob(dataExistSensors[index].tech_requirement, 'pernyataan_vendor_file_id',index)
                                    "></file-input>
                                  </base-input>
                                  <small v-if="dataExistSensors[index].tech_requirement.pernyataan_vendor_file_id"
                                    class="d-block mt-3 mb-2 text-justify">
                                    <a :href="getBlobsFile(dataExistSensors[index].tech_requirement.pernyataan_vendor_file_id)"
                                      target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                  </small>
                                 
                                    <small class="d-block  text-justify">
                                      Khusus untuk perusahaan atau vendor yang merakit
                                      alat sensor,
                                      <b>secara mandiri</b> menyampaikan surat
                                      pernyataan.
                                    </small>
                                  </div>
                                </div>
                              </form>
                          </div>
                        </div>  
                        </div>
      
                        <button @click="finalSubmit(dataExistSensors[index])" class="btn btn-primary">UBAH TITIK PENAATAN</button>
                          
                        </div>
                      </div>

                      <!-- ======================================= LOGGER ======================================= -->
                      <div v-if="updateSection == 'logger'">
                          <!-- Logger Section -->
                            <h3 class="text-center mb-3"> Logger : {{ dataExistSensors[index].spot_name }} </h3>
                            <!-- Brand -->
                            <div class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Brand</label>
                              <div class="col-lg-9">
                                <base-input name="Brand Logger" v-model="dataExistSensors[index].logger.brand" :rules="{required: true}"/>
                                <small class="d-block mt--3 text-justify">
                                  Jika menggunakan data logger Lokal (Rakitan
                                  Lokal), disampaikan spesifikasinya
                                </small>
                              </div>
                            </div>

                            <!-- Model -->
                            <div class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Model</label>
                              <div class="col-lg-9">
                                <base-input name="Model" v-model="dataExistSensors[index].logger.model" :rules="{required: true}"/>
                              </div>
                            </div>

                            <!-- Serial number -->
                            <div class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">Serial Number</label>
                              <div class="col-lg-9">
                                <base-input
                                  name="Serial Number"
                                  v-model="dataExistSensors[index].logger.sn"
                                  :rules="{required: true}"
                                />
                              </div>
                            </div>

                            <!-- Mac Address -->
                            <div class="form-group row">
                              <label class="col-lg-3 col-form-label form-control-label">MAC Address</label>
                              <div class="col-lg-9">
                                <base-input
                                  name="MAC Address"
                                  v-model="dataExistSensors[index].logger.mac"
                                  :rules="{required: true}"
                                />
                                <small class="d-block mt--3 text-justify"
                                  >Contoh: OA:0E:AA:8R:77</small
                                >
                              </div>
                            </div>

                            <button @click="finalSubmit(dataExistSensors[index].logger)" class="btn btn-primary">UBAH DATA LOGGER</button>
                      </div>

                      <!-- ======================================= SENSOR ======================================= -->
                      <div class="card bg-white border-0" v-if="updateSection == 'sensor'">
                        <h3 class="text-center mb-3">Sensor</h3>
                        <div class="card-body p-4">
                          <div v-for="(item, indexs) in dataExistSensors[index].logger.sensors" :key="indexs">
                            <div class="form-group row">
                              <label class="col-lg-2 col-form-label form-control-label">{{ dataExistSensors[index].logger.sensors[indexs].type }}</label>
                              <div class="col-lg-10">
                                <base-checkbox
                                  v-model="dataExistSensors[index].logger.sensors[indexs].is_active"
                                  class="mt-2 mb-3"
                                >
                                  {{
                                    dataExistSensors[index].logger.sensors[indexs].is_active
                                      ? "Digunakan"
                                      : "Tidak digunakan"
                                  }}
                                </base-checkbox>
                                <small class="d-block mt--3 text-justify">
                                  Aktifkan checkbox jika sensor digunakan untuk
                                  mengisi data sensor
                                </small>
                              </div>
                            </div>

                            <template v-if="dataExistSensors[index].logger.sensors[indexs].is_active">
                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10 p-0">
                                  <label
                                    class="col-12 col-form-label form-control-label py-0"
                                    >Brand</label
                                  >
                                  <div class="col-12">
                                    <base-input
                                      rules="required"
                                      :name="`brand`"
                                      v-model="dataExistSensors[index].logger.sensors[indexs].brand"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10 p-0">
                                  <label
                                    class="col-12 col-form-label form-control-label py-0"
                                    >Probe</label
                                  >
                                  <div class="col-12">
                                    <base-input
                                      :name="`Probe`"
                                      rules="required"
                                      v-model="dataExistSensors[index].logger.sensors[indexs].probe"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10 p-0">
                                  <label
                                    class="col-11 col-form-label form-control-label py-0"
                                    >Brosur</label
                                  >
                                  <div class="col-12">
                                    <base-input :name="`Brosur ${dataExistSensors[index].logger.sensors[indexs].type}`">
                                      <file-input accept="application/pdf" :ref="'brochure_file_id_' + index"
                                        @change="uploadFileSensorBlob(dataExistSensors[index].logger.sensors[indexs],'brochure_file_id',`brochure_file_id_${index}`)" />
                                    </base-input>
                                    <small v-if="dataExistSensors[index].logger.sensors[indexs].brochure_file_id
                                      " class="d-block mt-3 text-justify">
                                      <a :href="getBlobsFile(dataExistSensors[index].logger.sensors[indexs].brochure_file_id)"
                                        target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                                    </small>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10">
                                  <label
                                    class="col-11 col-form-label form-control-label px-0"
                                    >Range Pengukuran Sensor</label
                                  >
                                  <div class="row">
                                    <div class="col-6">
                                      <div class="form-group row">
                                        <div class="col-lg-12">
                                          <base-input
                                            :name="`Range Pengukuran Sensor`"
                                            rules="required"
                                            type="number"
                                            v-model="dataExistSensors[index].logger.sensors[indexs].min"
                                          />
                                          <small class="d-block mt--4 text-justify"
                                            >Minimum</small
                                          >
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group row">
                                        <div class="col-lg-12">
                                          <base-input
                                            rules="required"
                                            :name="`Range Pengukuran Sensor `"
                                            type="number"
                                            v-model="dataExistSensors[index].logger.sensors[indexs].max"
                                          />
                                          <small class="d-block mt--4 text-justify"
                                            >Maximum</small
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10">
                                  <label
                                    class="col-11 col-form-label form-control-label px-0"
                                    >Baku Mutu Air Limbah Sesuai Izin</label
                                  >
                                  <div v-if="dataExistSensors[index].logger.sensors[indexs].type === 'pH'" class="row">
                                    <div class="col-6">
                                      <div class="form-group row">
                                        <div class="col-lg-12">
                                          <base-input
                                            :name="`Baku Mutu Minimal`"
                                            rules="required"
                                            type="number"
                                            v-model="dataExistSensors[index].logger.sensors[indexs].bmal_min"
                                          />
                                          <small class="d-block mt--4 text-justify"
                                            >Minimum</small
                                          >
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group row">
                                        <div class="col-lg-12">
                                          <base-input
                                            type="number"
                                            :name="`Baku Mutu`"
                                            rules="required"
                                            v-model="dataExistSensors[index].logger.sensors[indexs].bmal_max"
                                          />
                                          <small class="d-block mt--4 text-justify"
                                            >Maximum</small
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                <div
                                    v-else-if="dataExistSensors[index].logger.sensors[indexs].type === 'Debit'"
                                    class="row"
                                  >
                                    <div class="col-12">
                                      <div class="form-group row">
                                        <div class="col-lg-9">
                                          <base-input
                                            type="number"
                                            rules="required"
                                            name="Debit maksimum"
                                            v-model="dataExistSensors[index].logger.sensors[indexs].bmal_max"
                                          />
                                          <small class="d-block mt--4 text-justify"
                                            >Maximum</small
                                          >
                                        </div>
                                        <div class="col-3">
                                          <base-input
                                            name="Satuan sensor debit"
                                            rules="required"
                                          >
                                            <el-select
                                              v-model="
                                                dataExistSensors[index].logger.sensors[indexs].bmal_unit
                                              "
                                            >
                                              <el-option
                                                v-for="(unit, index) in debitUnit"
                                                :key="index"
                                                :label="unit"
                                                :value="unit"
                                              />
                                            </el-select>
                                          </base-input>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-else class="row">
                                    <div class="col-12">
                                      <div class="form-group row">
                                        <div class="col-lg-12">
                                          <base-input
                                            type="number"
                                            rules="required"
                                            :name="``"
                                            v-model="dataExistSensors[index].logger.sensors[indexs].bmal_max"
                                          />
                                          <small class="d-block mt--4 text-justify"
                                            >Maximum</small
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10 p-0">
                                  <label
                                    class="col-12 col-form-label form-control-label py-0"
                                    >Metode Pengukuran</label
                                  >
                                  <div class="col-12">
                                    <base-input
                                      rules="required"
                                      :name="`Metode Pengukuran`"
                                      v-model="dataExistSensors[index].logger.sensors[indexs].bmal_measure"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10 p-0">
                                  <label
                                    class="col-12 col-form-label form-control-label py-0"
                                  >
                                    Jadwal Kalibrasi
                                  </label>
                                  <div class="col-12">
                                    <base-input
                                      rules="required"
                                      :name="`Jadwal Kalibrasi `"
                                    >
                                      <el-select
                                        v-model="dataExistSensors[index].logger.sensors[indexs].calibration_schedule"
                                      >
                                        <el-option
                                          v-for="bulan in 12"
                                          :key="bulan"
                                          :label="`${bulan} Bulan`"
                                          :value="`${bulan}`"
                                        />
                                      </el-select>
                                    </base-input>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10 p-0">
                                  <label
                                    class="col-12 col-form-label form-control-label py-0"
                                    >Tanggal Kalibrasi</label
                                  >
                                  <div class="col-12">
                                    <base-input
                                      class="m-0"
                                      rules="required"
                                      :name="`Tanggal Kalibrasi `"
                                    >
                                      <flat-picker
                                        :config="selects.configs.flatpickr"
                                        class="form-control datepicker"
                                        v-model="
                                          dataExistSensors[index].logger.sensors[indexs].calibration_date
                                        "
                                      />
                                    </base-input>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-lg-2"></div>
                                <div class="form-group col-lg-10 p-0">
                                  <label
                                    class="col-12 col-form-label form-control-label py-0"
                                    >Metode Kalibrasi</label
                                  >
                                  <div class="col-12">
                                    <base-input
                                      rules="required"
                                      :name="`Metode Kalibrasi`"
                                      v-model="dataExistSensors[index].logger.sensors[indexs].calibration_method"
                                    />
                                  </div>
                                </div>
                              </div>

                            </template>

                          </div>
                          <button @click="finalSubmit(dataExistSensors[index].logger)" class="btn btn-primary">UBAH DATA SENSOR</button>
                          <!-- <base-input
                            :rules="{ required: { allowFalse: false } }"
                            name="Sensor"
                          >
                            <base-checkbox v-model="form.sensorDone"
                              >Data sensor sudah benar</base-checkbox
                            >
                          </base-input> -->
                        </div>
                      </div>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import API from "@/api/base_url.js";
import FileInput from "@/components/Inputs/FileInput";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  Select,
  Option,
  Table,
  TableColumn,
  Collapse,
  CollapseItem,
} from "element-ui";
import Modal from "@/components/Modal.vue";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import PendaftaranSparingTable from "../../../Tables/PendaftaranSparing.vue";
import BaseHeader from "@/components/BaseHeader";
import SparingTmpTable from "../../../Tables/SparingTmpTable.vue";
import SparingTmpPenaatanTable from "../../../Tables/SparingTmpPenaatan.vue";
import SparingTmpDataLoggerTable from "../../../Tables/SparingTmpDataLoggerTable.vue";
import { BasePagination } from "@/components";
import clientPaginationMixin from "../../../Tables/PaginatedTables/clientPaginationMixin";
import moment from "moment";
import baseURL from "@/api/base_url_backend.js";


export default {
  name: "pendaftaranSparing",
  mixins: [clientPaginationMixin],
  components: {
    SparingTmpDataLoggerTable,
    SparingTmpTable,
    SparingTmpPenaatanTable,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    BaseHeader,
    FileInput,
    HtmlEditor,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    PendaftaranSparingTable,
    BasePagination,
  },
  mounted() {
    this.getDataForOldLater();
    this.getExistData()
  },
  data() {
    return {
      station: {
        spot_name: "",
        waste_receiver: "",
        das: "",
        longitude: 0,
        latitude: 0,
        processing_technique: "",
        waste_type: "",
        debit_value: 0,
        debit_unit: "",
        capacity_permit: 0,
        capacity_value: 0,
        frequency: {
          bulan: 0,
          detail_eng_ipal: "",
          hari: 0,
          ipal_file_id: "",
          jam: 0,
          latar_belakang: "",
          logbook_file_id: "",
          metode_ukur: "",
          type: ""
        },
        tech_requirement: {
          dukungan_brand_file_id: "",
          info_alat: "",
          kalibrasi_awal_file_id: "",
          kalibrasi_rutin_file_id: "",
          kinerja_file_id: "",
          kontak_kerjasama_file_id: "",
          lulus_uji_file_id: "",
          nama_penyedia: "",
          no_surat: "",
          pernyataan_vendor_file_id: "",
          tipe_uji: ""
        },
        logger: {
          brand: "",
          mac: "",
          model: "",
          sn: "",
          sensors: Array.from({ length: 5 }, (_, index) => ({
            bmal_max: 0,
            bmal_measure: "",
            bmal_min: 0,
            bmal_unit: "",
            brand: "",
            brochure_file_id: "",
            calibration_date: "",
            calibration_method: "",
            calibration_schedule: "",
            is_active: false,
            max: 0,
            min: 0,
            probe: "",
            type: index === 0 ? "pH" : index === 1 ? "COD" : index === 2 ? "TSS" : index === 3 ? "NH3N" : "Debit"
          }))
        }
      },
      isHidden: null,
      mainIdWillUpdate: "",
      updateSection: "",
      dataExist: [],
      dataExistSensors: [],
      getStationForUpdate:"",
      newPayloadTmp: [],
      editLogger: false,
      editLoggerID: "",
      tableLoggerSensor: [],
      loggerRegistered: [],
      pendaftaranRubahSensorID: "",
      rubahSensorPendaftaran: false,
      populatedSensorExisting: [],
      fileSuratKeteranganLulusUji: 0,
      fileHasilKalibrasiSaatAwalPemasanganAlat: 0,
      fileHasilKalibrasiAlatRutin: 0,
      fileHasilUjiKinerjaDariLabotarium: 0,
      fileSuratDukunganBrandSensor: 0,
      fileSuratPernyataan: 0,
      fileKontakKerjaSama: 0,
      fileBrosur: 0,
      sensorTmpData: [],
      testingbro: "",
      getLoggerHasSubmit: [],
      getReturnedIdCreated: "",
      indexSensor: null,
      getFormSensor: null,
      loggerFormInput: new FormData(),
      sensorFormInput: new FormData(),
      // triggerModal
      showUpdateFormPenataanFormModal: false,
      showFormLoggerSensorFormModal: false,
      showFormLoggerSensorConnectingFormModal: false,
      showFormModal: false,
      showFormModalWithExistPermission: false,
      showMainWizardForm: false,
      showMainWizardPointForm: false,
      showSecondaryWizardForm: false,
      showThirdWizardForm: false,
      showFormPenataanFormModal: false,
      isLoading:false,
      // table temporary created
      tableTitikPenaatan: [
        {
          prop: "spotLoc[0]",
          label: "Lintang",
          minWidth: 150,
        },
        {
          prop: "spotLoc[1]",
          label: "Bujur",
          minWidth: 160,
        },
      ],

      // catch form temp table
      titikPenaatanPopUpForm: {
        namaTitikPenaatan: "",
        penerimaAirLimbah: "",
        das: "",
        lintang: "",
        bujur: "",
      },
      loggerSensorPopUpForm: {
        brand: "",
        model: "",
        sn: "",
        mac: "",
        lulusUji: "",
        infoAlat: "",
        namaPenyedia: "",
        noSurat: "",
        suratketerangan: "",
      },
      loggerSensorIndex: {},
      newsensor: [],
      // catch form temp edit table
      titikPenaatanPopUpEditForm: {
        id: "",
        spotName: "",
        spotLoc: "",
        penerimaAirLimbah: "",
        das: "",
      },
      // main payload
      sensorsInit: Array.from({ length: 5 }, (_, index) => ({
            bmal_max: 0,
            bmal_measure: "",
            bmal_min: 0,
            bmal_unit: "",
            brand: "",
            brochure_file_id: "",
            calibration_date: new Date(),
            calibration_method: "",
            calibration_schedule: "",
            is_active: false,
            max: 0,
            min: 0,
            probe: "",
            type: index === 0 ? "pH" : index === 1 ? "COD" : index === 2 ? "TSS" : index === 3 ? "NH3N" : "Debit"
        })),
      form: {
        uid: "",
        prosesProduksi: {
          debitPenggunaan: {
            value: null,
            satuan: null,
            keterangan: null,
          },
          debitLimbahTotal: {
            value: null,
            satuan: null,
            keterangan: null,
          },
          debitReuse: {
            value: null,
            satuan: null,
            keterangan: null,
          },
          debitRecycle: {
            value: null,
            satuan: null,
            keterangan: null,
          },
          debitRecovery: {
            value: null,
            satuan: null,
            keterangan: null,
          },
          debitOlahIPAL: {
            value: null,
            satuan: null,
            keterangan: null,
          },
          fileNeracaAirLimbah: {
            path: "",
            upload: 0,
            progress: 0,
          },
        },
        teknis: {
          permitFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          uuid: "1234-5678-90",
          permitPerson: null,
          permitNumber: null,
          permitDatePublish: this.getFormayYear(new Date()),
          permitDateExpire: this.getFormayYear(new Date()),
          sparing_air_limbah_file:"",
          wasteSource: null,
          spotCount: null,
          spotList: [],
          processingTechnique: null,
          wasteType: null,
          debit: null,
          satuanDebit: "m3/detik",
          capacityPermit: null,
          capacityActual: null,
          frequenceDet: "",
        },
        validitas: {
          infoAlat: null,
          lulusUji: null,
          namaPenyedia: null,
          noSurat: null,
          jadwalKalibrasi: null,
          kontrak: {
            path: "",
            upload: 0,
            progress: 0,
          },
          kinerjaFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          konektifitasFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          kalibrasiFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          kalibrasiRutinFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          validitasFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
        },
        frekuensi: {
          latarBelakang: "",
          detEngIPAL: "",
          fotoIPAL: {
            path: "",
            upload: 0,
            progress: 0,
          },
          jam: null,
          hari: null,
          bulan: null,
          metUkurDebit: "",
          logBook: {
            path: "",
            upload: 0,
            progress: 0,
          },
        },
        logger: [],
        sensor: [
          {
            name: "pH",
            label: "pH",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
          {
            name: "COD",
            label: "COD",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
          {
            name: "TSS",
            label: "TSS",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
          {
            name: "NH3N",
            label: "NH3N",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
          {
            name: "Debit",
            label: "Debit",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
        ],
        sensorDone: false,
        surat: {
          dukunganBrand: {
            path: "",
            upload: 0,
            progress: 0,
          },
          pernyataanVendor: {
            path: "",
            upload: 0,
            progress: 0,
          },
        },
      },
      // createPermissionLater
      permissionLaterForm: new FormData(),
      permissionLaterFormExisting: [],
      permissionLaterFormExistingSelected: "",
      // another
      debits: [],
      selectedRows: [],
      totalCount: 0,
      activeName: 1,
      isFormSuratIzinShow: false,
      selects: {
        configs: {
          flatpickr: {
            allowInput: true,
            altFormat: "Y-m-d",
            altInput: false,
            dateFormat: "Y-m-d",
            mode: "single",
          },
        },
        options: {
          role: [
            {
              label: "Pusat",
              value: "admin",
            },
            {
              label: "Provinsi",
              value: "prov",
            },
            {
              label: "Kab/Kot",
              value: "kabkot",
            },
            {
              label: "Industri",
              value: "comp",
            },
          ],
          type: [
            { name: "Semua", value: "" },
            { name: "Industri Rayon", value: "Industri Rayon" },
            { name: "Industri Pulp/Kertas", value: "Industri Pulp/Kertas" },
            {
              name: "Industri Petrokimia Hulu",
              value: "Industri Petrokimia Hulu",
            },
            {
              name: "Industri Oleokimia Dasar",
              value: "Industri Oleokimia Dasar",
            },
            { name: "Industri Minyak Sawit", value: "Industri Minyak Sawit" },
            { name: "Pengolahan Minyak Bumi", value: "Pengolahan Minyak Bumi" },
            {
              name: "Eksplorasi dan Produksi Migas",
              value: "Eksplorasi dan Produksi Migas",
            },
            {
              name: "Pertambangan Emas dan Tembaga",
              value: "Pertambangan Emas dan Tembaga",
            },
            { name: "Pertambangan Batu Bara", value: "Pertambangan Batu Bara" },
            { name: "Industri Tekstil", value: "Industri Tekstil" },
            { name: "Pertambangan Nikel", value: "Pertambangan Nikel" },
            { name: "Kawasan Industri", value: "Kawasan Industri" },
          ],
          prov: [],
          city: [],
          comp: [
            {
              label: "Semua",
              value: 0,
            },
            {
              label: "Alpha",
              mail: "alpha",
              value: 1,
            },
            {
              label: "Bravo",
              mail: "bravo",
              value: 2,
            },
            {
              label: "Charlie",
              mail: "charlie",
              value: 3,
            },
            {
              label: "Delta",
              mail: "delta",
              value: 4,
            },
          ],
          emsg: [
            "Data sensor pH melebihi ambang batas!",
            "Data sensor COD melebihi ambang batas!",
            "Data sensor TSS melebihi ambang batas!",
            "Data sensor NH3N melebihi ambang batas!",
            "Data sensor debit melebihi ambang batas!",
          ],
          parameter: [
            {
              label: "pH",
              value: "ph",
            },
            {
              label: "COD",
              value: "cod",
            },
            {
              label: "TSS",
              value: "tss",
            },
            {
              label: "NH3N",
              value: "nh3n",
            },
            {
              label: "Debit",
              value: "debit",
            },
          ],
        },
        type: null,
        prov: null,
        city: null,
        comp: null,
        time: null,
      },
      provideSelect: {
        provinsi: [],
        kabupatenKota: [],
        daftarPerusahaan: [],
        tmp: [],
        uploadChoosen: null,
      },
      showFormRegisterNormally: false,
      showFormRegisterWaste: false,
      cekStatusForm: false,
      isNormalOrWaste: false,
      showForm: false,
      showAnnoncementModal: false,
      showAllButtonModal: false,
      isShowModalForceUpdate: false,
      showNoteModal: false,
      updateNote: "",
      forceUpdateData: null,
      edit: false,
      editID: "",
      isEditData: 0,
      isSectionPendaftaran: 0,
      uploading: false,
      isDataCorrect: true,
      res: { comp: null, user: null, logger: null },
      temptSelect: null,
      debitUnit: ["m3/detik", "m3/menit", "m3/jam", "m3/hari", "m3/bulan"],
      countTitik: 1,
      countWizard: 1,
      formData: new FormData(),
      formDataSparing: new FormData(),
    };
  },
  methods: {
    // start upload
    uploadFileBlob(form, field,index) {
      let field_new = field+index;
      const fileInput = this.$refs[field_new][0];
    
      const file = fileInput.files[0];
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        Swal.fire("Kesalahan", "Hanya file PDF dan gambar yang diizinkan", "error");
        return;
      }

      const maxSize = 5 * 1024 * 1024;
      if (file.size > maxSize) {
        Swal.fire("Kesalahan", "Ukuran file tidak boleh lebih dari 5MB", "error");
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      this.isLoading = true;
      API.post(`blobs/upload/public`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          let data = res.data.data;
          let value = data.id;
          this.$set(form, field, value);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
        });
    },
    uploadPermitFileBlob(form, field) {

      // ${defaults.baseURL}/file
      const fileInput = this.$refs[field];
      const file = fileInput.files[0];

      // Validasi jenis file (hanya PDF dan gambar)
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        Swal.fire("Kesalahan", "Hanya file PDF dan gambar yang diizinkan", "error");
        return;
      }

      // Validasi ukuran file (tidak lebih dari 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB dalam bytes
      if (file.size > maxSize) {
        Swal.fire("Kesalahan", "Ukuran file tidak boleh lebih dari 5MB", "error");
        return;
      }


      const formData = new FormData();
      formData.append("file", file);
      this.isLoading = true;
      API.post(`blobs/upload/public`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          let data = res.data.data;
          let value = data.id;
          this.$set(form, field, value);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
        });
      },
    uploadFileSensorBlob(form,field,ref) {
      // ${defaults.baseURL}/file
      const fileInput = this.$refs[ref][0];

      const file = fileInput.files[0];

      // Validasi jenis file (hanya PDF dan gambar)
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        Swal.fire("Kesalahan", "Hanya file PDF dan gambar yang diizinkan", "error");
        return;
      }

      // Validasi ukuran file (tidak lebih dari 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB dalam bytes
      if (file.size > maxSize) {
        Swal.fire("Kesalahan", "Ukuran file tidak boleh lebih dari 5MB", "error");
        return;
      }


      const formData = new FormData();
      formData.append("file", file);
      this.isLoading = true;
      API.post(`blobs/upload/public`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          let data = res.data.data;
          let value = data.id;
          this.$set(form, field, value);
          console.log(this.$set(form, field, value))
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
        });
    },
    getBlobsFile(filename) {
      let url = `${baseURL}blobs/download/${filename}`
      return url
    },
    resetForm(formData) {
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          if (typeof formData[key] === 'object' && formData[key] !== null) {
            if (formData[key].hasOwnProperty('path')) {
              formData[key].path = "";
            } else if (formData[key].hasOwnProperty('file')) {
              formData[key].file = null;
            } else {
              this.resetForm(formData[key]); // Panggil secara rekursif untuk objek dalamnya
            }
          } else {
            formData[key] = ""; // Atur ulang nilai properti jika bukan objek
          }
        }
      }
    },
    resetFileInput(ref) {
    if (this.$refs[ref]) {
      // this.$refs.lulus_uji_file_id.files = []
      this.$refs[ref].files = []
      const inputElement = this.$refs[ref].$el.querySelector('input[type="file"]');
      inputElement.value = ''
    }
    },
    resetAllFileInputs(refs) {
      refs.forEach(ref => this.resetFileInput(ref));
    },
    // end of upload
    toggleAccordion(type, id, index) {
        // this.getDataTitikPenaatanDetail(id)
        if (type == 'Logger') {
          this.isOpenLogger = !this.isOpenLogger
          this.isOpenSensor = !this.isOpenSensor
        } 

        if (this.isHidden == null) {
          console.log(index);
          this.isHidden = index
        } else {
          this.isHidden = null
        }
      },
    changeUpdateSection(val) {
      this.updateSection = val
    },
    onUpdateSparing() {
      console.log('update');
      Swal.fire({
        title: "Ubah Data?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "UBAH",
        cancelButtonText: "BATAL"
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          let payload = {

          }

          API.put(`sparing/${this.$route.params.id}`, payload, { headers })
          .then(({ data: content }) => {
            Swal.fire("Perubahan Data Berhasil", "", "success");
            this.$router.push("/sparing");
          })
          .catch((err) => {
            Swal.fire("Gagal Merubah Data", "", "error");
          });
        }
      });
    },
    getExistData() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get(`sparing/${this.$route.params.id}`, { headers })
      .then(({ data: content }) => {
        // this.dataExist = content.data
        this.permissionLaterFormExistingSelected = content.data.permit_id
        this.form.teknis.wasteSource = content.data.station[0].waste_source
        this.mainIdWillUpdate = content.data.id
        this.dataExistSensors = content.data.station

       // Loop melalui setiap objek dalam dataExistSensors
        this.dataExistSensors.forEach(sensor => {
          // Periksa apakah logger.sensors tidak ada atau kosong
          if (!sensor.logger || !sensor.logger.sensors || sensor.logger.sensors.length === 0) {
            // Jika kosong, tambahkan sensorsInit ke dalam logger.sensors
            sensor.logger = {
              ...sensor.logger,
              sensors: this.sensorsInit.map(s => ({ ...s }))
            };
          }
        });

        console.log('data',this.dataExistSensors);

      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Gagal Memuat Data", "", "error");
      });
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    submitStation() {

      Swal.fire({
        title: "Simpan Titik Penaatan ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "SIMPAN",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {

          let tmpSensor = []
          for (let i = 0; i < this.form.sensor.length; i++) {
            tmpSensor.push({
              bmal_max: +this.form.sensor[i].maxMeasure,
              bmal_measure: this.form.sensor[i].metodePengukuran,
              bmal_min: +this.form.sensor[i].minMeasure,
              bmal_unit: this.form.sensor[i].satuanDebit,
              brand: this.form.sensor[i].brand,
              brochure_file_id: "/storage/2020-09-17T09:08:10.726ZBrosur pH and ORP.pdf",
              calibration_date: this.formatDate(this.form.sensor[i].tanggalKalibrasi),
              calibration_method: this.form.sensor[i].metodeKalibrasi,
              calibration_schedule: +this.form.sensor[i].jadwalKalibrasi,
              is_active: this.form.sensor[i].active ? 1 : 0,
              max: +this.form.sensor[i].max,
              min: +this.form.sensor[i].min,
              probe: this.form.sensor[i].probe,
              type: this.form.sensor[i].label
            })
            
          }


          this.newPayloadTmp.push(
            {
              namaTitikPenaatan: this.titikPenaatanPopUpForm.namaTitikPenaatan,
              wasteSource: this.form.teknis.wasteSource,
              namaTitikPenaatan: this.titikPenaatanPopUpForm.namaTitikPenaatan,
              lintang: this.titikPenaatanPopUpForm.lintang,
              bujur: this.titikPenaatanPopUpForm.bujur,
              das: this.titikPenaatanPopUpForm.das,
              processingTechnique: this.form.teknis.processingTechnique,
              wasteType: this.form.teknis.wasteType,
              debit: this.form.teknis.debit,
              satuanDebit: this.form.teknis.satuanDebit,
              capacityPermit: this.form.teknis.capacityPermit,
              capacityActual: this.form.teknis.capacityActual,
              frequenceDet: this.form.teknis.frequenceDet,
              jam: this.form.frekuensi.jam,
              minggu: this.form.frekuensi.hari,
              bulan: this.form.frekuensi.bulan,
              latarBelakang: this.form.frekuensi.latarBelakang,
              detEngIPAL: this.form.frekuensi.detEngIPAL,
              metUkurDebit: this.form.frekuensi.metUkurDebit,
              lulusUji: this.loggerSensorPopUpForm.lulusUji,
              noSurat: this.loggerSensorPopUpForm.noSurat,
              namaPenyedia: this.loggerSensorPopUpForm.namaPenyedia,
              infoAlat: this.loggerSensorPopUpForm.infoAlat,
              brand: this.loggerSensorPopUpForm.brand,
              model: this.loggerSensorPopUpForm.model,
              mac: this.loggerSensorPopUpForm.mac,
              sn: this.loggerSensorPopUpForm.sn,
              sensor: tmpSensor
            }
          )

        this.resetForm()        

        this.showFormPenataanFormModal = false
        Swal.fire({
          title: "Berhasil",
          text: "titik penaatan disimpan",
          icon: "success",
        });
        }
      });
    },
    updateStation() {
      let indexToUpdate = this.newPayloadTmp.findIndex(
        (tableRow) => tableRow.namaTitikPenaatan === this.getStationForUpdate
      );


      Swal.fire({
        title: "Perbaharui Titik Penaatan ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "PERBAHARUI",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
              this.newPayloadTmp[indexToUpdate].namaTitikPenaatan = this.titikPenaatanPopUpForm.namaTitikPenaatan,
              this.newPayloadTmp[indexToUpdate].wasteSource = this.form.teknis.wasteSource,
              this.newPayloadTmp[indexToUpdate].namaTitikPenaatan = this.titikPenaatanPopUpForm.namaTitikPenaatan,
              this.newPayloadTmp[indexToUpdate].lintang = this.titikPenaatanPopUpForm.lintang,
              this.newPayloadTmp[indexToUpdate].bujur = this.titikPenaatanPopUpForm.bujur,
              this.newPayloadTmp[indexToUpdate].das = this.titikPenaatanPopUpForm.das,
              this.newPayloadTmp[indexToUpdate].processingTechnique = this.form.teknis.processingTechnique,
              this.newPayloadTmp[indexToUpdate].wasteType = this.form.teknis.wasteType,
              this.newPayloadTmp[indexToUpdate].debit = this.form.teknis.debit,
              this.newPayloadTmp[indexToUpdate].satuanDebit = this.form.teknis.satuanDebit,
              this.newPayloadTmp[indexToUpdate].capacityPermit = this.form.teknis.capacityPermit,
              this.newPayloadTmp[indexToUpdate].capacityActual = this.form.teknis.capacityActual,
              this.newPayloadTmp[indexToUpdate].frequenceDet = this.form.teknis.frequenceDet,
              this.newPayloadTmp[indexToUpdate].jam = this.form.frekuensi.jam,
              this.newPayloadTmp[indexToUpdate].minggu = this.form.frekuensi.minggu,
              this.newPayloadTmp[indexToUpdate].bulan = this.form.frekuensi.bulan,
              this.newPayloadTmp[indexToUpdate].latarBelakang = this.form.frekuensi.latarBelakang,
              this.newPayloadTmp[indexToUpdate].detEngIPAL = this.form.frekuensi.detEngIPAL,
              this.newPayloadTmp[indexToUpdate].metUkurDebit = this.form.frekuensi.metUkurDebit,
              this.newPayloadTmp[indexToUpdate].lulusUji = this.loggerSensorPopUpForm.lulusUji,
              this.newPayloadTmp[indexToUpdate].noSurat = this.loggerSensorPopUpForm.noSurat,
              this.newPayloadTmp[indexToUpdate].namaPenyedia = this.loggerSensorPopUpForm.namaPenyedia,
              this.newPayloadTmp[indexToUpdate].infoAlat = this.loggerSensorPopUpForm.infoAlat,
              this.newPayloadTmp[indexToUpdate].brand = this.loggerSensorPopUpForm.brand,
              this.newPayloadTmp[indexToUpdate].model = this.loggerSensorPopUpForm.model,
              this.newPayloadTmp[indexToUpdate].mac = this.loggerSensorPopUpForm.mac,
              this.newPayloadTmp[indexToUpdate].sn = this.loggerSensorPopUpForm.sn,
              this.newPayloadTmp[indexToUpdate].sensor = this.form.sensor

              // NANTI BALIK KESINI YA

          this.showFormPenataanFormModal = false
          Swal.fire({
            title: "Berhasil",
            text: "Berhasil Memperbaharui titik penaatan",
            icon: "success",
          });
        }
      });
    },
    populateUpdateStation(val) {
          this.showFormPenataanFormModal = true
          this.getStationForUpdate = val.namaTitikPenaatan

          this.titikPenaatanPopUpForm.namaTitikPenaatan = val.namaTitikPenaatan
          this.titikPenaatanPopUpForm.lintang = val.lintang
          this.titikPenaatanPopUpForm.bujur = val.bujur
          this.titikPenaatanPopUpForm.das = val.das
          this.form.teknis.processingTechnique = val.processingTechnique
          this.form.teknis.wasteType = val.wasteType
          this.form.teknis.debit = val.debit
          this.form.teknis.satuanDebit = val.satuanDebit
          this.form.teknis.capacityPermit = val.capacityPermit
          this.form.teknis.capacityActual = val.capacityActual
          this.form.teknis.frequenceDet = val.frequenceDet
          this.form.frekuensi.jam = val.jam
          this.form.frekuensi.minggu = val.minggu
          this.form.frekuensi.bulan = val.bulan
          this.form.frekuensi.latarBelakang = val.latarBelakang
          this.form.frekuensi.detEngIPAL = val.detEngIPAL
          this.form.frekuensi.metUkurDebit = val.metUkurDebit
          this.loggerSensorPopUpForm.lulusUji = val.lulusUji
          this.loggerSensorPopUpForm.noSurat = val.noSurat
          this.loggerSensorPopUpForm.namaPenyedia = val.namaPenyedia
          this.loggerSensorPopUpForm.infoAlat = val.infoAlat
          this.loggerSensorPopUpForm.brand = val.brand
          this.loggerSensorPopUpForm.model = val.model
          this.loggerSensorPopUpForm.mac = val.mac
          this.loggerSensorPopUpForm.sn = val.sn
    },
    finalSubmit(id) {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      if (this.updateSection == 'main') {
        console.log('main', id);
        Swal.fire({
        title: `Perbaharui Surat Izin ?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "YA",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            detail: {
              permit_id: this.permissionLaterFormExistingSelected,
            }
          }

          API.put(`sparing/${id}`,  payload, { headers })
          .then(({ data: content }) => {
            Swal.fire({title: "Berhasil Memperbaharui Surat Izin",text: "",icon: "success"});
            this.getExistData()
            this.updateSection = ""
            this.$router.push('/sparing')
          })
          .catch((err) => {
            console.log(err);
            Swal.fire("Memperbaharui Data Gagal","","error");
          });
        }
      });
      }

      if (this.updateSection == 'station') {
        Swal.fire({
        title: `Perbaharui Titik Penaatan ?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "YA",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            capacity_permit: +id.capacity_permit,
            capacity_value: +id.capacity_value,
            das: id.das,
            debit_unit: id.debit_unit,
            debit_value: +id.debit_value,
            frequency: {
              bulan: +id.frequency.bulan,
              detail_eng_ipal: id.frequency.detail_eng_ipal,
              hari: +id.frequency.hari,
              ipal_file_id: id.frequency.ipal_file_id,
              jam: +id.frequency.jam,
              latar_belakang: id.frequency.latar_belakang,
              logbook_file_id: id.frequency.logbook_file_id,
              metode_ukur: id.frequency.metode_ukur,
              type: id.frequency.type
            },
            tech_requirement:{
              dukungan_brand_file_id:id.tech_requirement.dukungan_brand_file_id,
              info_alat:id.tech_requirement.info_alat,
              kalibrasi_awal_file_id: id.tech_requirement.kalibrasi_awal_file_id,
              kalibrasi_rutin_file_id: id.tech_requirement.kalibrasi_rutin_file_id,
              kinerja_file_id: id.tech_requirement.kinerja_file_id,
              kontak_kerjasama_file_id: id.tech_requirement.kontak_kerjasama_file_id,
              lulus_uji_file_id: id.tech_requirement.lulus_uji_file_id,
              nama_penyedia: id.tech_requirement.nama_penyedia,
              no_surat: id.tech_requirement.no_surat,
              pernyataan_vendor_file_id: id.tech_requirement.pernyataan_vendor_file_id,
              tipe_uji: id.tech_requirement.tipe_uji
            },
            latitude: +id.latitude,
            longitude: +id.longitude,
            processing_technique: id.processing_technique,
            spot_name: id.spot_name,
            waste_receiver: id.waste_receiver,
            waste_source: id.waste_source,
            waste_type: id.waste_type
          }

          API.put(`stations/${id.id}`,  payload, { headers })
          .then(({ data: content }) => {
            Swal.fire({title: "Berhasil Memperbaharui Titik Penaatan",text: "",icon: "success"});
            this.getExistData()
            this.updateSection = ""
            this.$router.push('/sparing')
          })
          .catch((err) => {
            console.log(err);
            Swal.fire("Memperbaharui Data Gagal","","error");
          });
        }
      });
      }

      if (this.updateSection == 'logger') {
        console.log(id);
        Swal.fire({
        title: `Perbaharui Logger ?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "YA",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            brand: id.brand,
            mac: id.mac,
            model: id.model,
            sn: id.sn,
          }

          API.put(`loggers/${id.id}`,  payload, { headers })
          .then(({ data: content }) => {
            Swal.fire({title: "Berhasil Memperbaharui Logger",text: "",icon: "success"});
            this.getExistData()
            this.updateSection = ""
            this.$router.push('/sparing')
          })
          .catch((err) => {
            console.log(err);
            Swal.fire("Memperbaharui Data Gagal","","error");
          });
        }
      });
      }

      if (this.updateSection == 'sensor') {
        Swal.fire({
        title: `Perbaharui Sensor ?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "YA",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {

          id.sensors.forEach(sensor => {
              sensor.bmal_min = Number(sensor.bmal_min);
              sensor.bmal_max = Number(sensor.bmal_max);
              sensor.min = Number(sensor.min);
              sensor.max = Number(sensor.max);
              sensor.calibration_schedule = Number(sensor.calibration_schedule)
              sensor.is_active = sensor.is_active ? true : false 
          });

          
          const modifiedSensors = id.sensors.map(sensor => {
              // Convert is_active from boolean to number
              return {
                  ...sensor,
                  is_active: sensor.is_active ? 1 : 0
              };
          });
          
        
          let payload = {
            sensors:  modifiedSensors
          }

          API.put(`loggers/${id.id}`,  payload, { headers })
          .then(({ data: content }) => {
            Swal.fire({title: "Berhasil Memperbaharui Sensor",text: "",icon: "success"});
            this.updateSection = ""
            this.getExistData()
            this.$router.push('/sparing')
          })
          .catch((err) => {
            console.log(err);
            Swal.fire("Memperbaharui Data Gagal","","error");
          });
        }
      });
      }


      // old
      // let stationContent = []
      // for (let i = 0; i < this.newPayloadTmp.length; i++) {
      //   stationContent.push({
      //     capacity_permit: +this.newPayloadTmp[i].capacityPermit,
      //     capacity_value: +this.newPayloadTmp[i].capacityActual,
      //     das: this.newPayloadTmp[i].das,
      //     debit_unit: this.newPayloadTmp[i].satuanDebit,
      //     debit_value: +this.newPayloadTmp[i].debit,
      //     frequency: {
      //       bulan: +this.newPayloadTmp[i].bulan,
      //       detail_eng_ipal: this.newPayloadTmp[i].detEngIPAL,
      //       hari: +this.newPayloadTmp[i].minggu,
      //       ipal_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       jam: +this.newPayloadTmp[i].jam,
      //       latar_belakang: this.newPayloadTmp[i].latarBelakang,
      //       logbook_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       metode_ukur: this.newPayloadTmp[i].metUkurDebit,
      //       type: this.newPayloadTmp[i].frequenceDet
      //     },
      //     latitude: +this.newPayloadTmp[i].lintang,
      //     logger: {
      //       brand: this.newPayloadTmp[i].brand,
      //       mac: this.newPayloadTmp[i].mac,
      //       model: this.newPayloadTmp[i].model,
      //       sn: this.newPayloadTmp[i].sn,
      //       sensors: this.newPayloadTmp[i].sensor,
      //     },
      //     longitude: +this.newPayloadTmp[i].bujur,
      //     processing_technique: this.newPayloadTmp[i].processingTechnique,
      //     spot_name: this.newPayloadTmp[i].namaTitikPenaatan,
      //     tech_requirement: {
      //       dukungan_brand_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       info_alat: this.newPayloadTmp[i].infoAlat,
      //       kalibrasi_awal_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       kalibrasi_rutin_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       kinerja_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       kontak_kerjasama_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       lulus_uji_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       nama_penyedia: this.newPayloadTmp[i].namaPenyedia,
      //       no_surat: this.newPayloadTmp[i].noSurat,
      //       pernyataan_vendor_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
      //       tipe_uji: this.newPayloadTmp[i].lulusUji
      //     },
      //     waste_receiver: "Laut",
      //     waste_source: this.newPayloadTmp[i].wasteSource,
      //     waste_type: this.newPayloadTmp[i].wasteType
      //   })
      // }

      // let payload = {
      //       detail: {
      //         permit_id: this.permissionLaterFormExistingSelected,
      //         total_station: stationContent.length
      //       },
      //       station: stationContent
      // }

      // Swal.fire({
      //   title: `Ubah SPARING?`,
      //   text: "",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#29664A",
      //   cancelButtonColor: "#d33",
      //   confirmButtonText: "DAFTAR",
      //   cancelButtonText: "BATAL",
      // }).then((result) => {
      //   if (result.isConfirmed) {
         

      //     if (!this.permissionLaterFormExistingSelected) {
      //       Swal.fire({
      //       title: "Nomor Surat wajib di isi",
      //       text: "Pastikan anda mengisi nomor surat dengan memilih yang sudah ada atau membuat permohonan surat baru",
      //       icon: "error",
      //     });
      //     } else if (!this.form.teknis.wasteSource) {
      //       Swal.fire({
      //       title: "Sumber Air Limbah wajib di isi",
      //       text: "Pastikan anda mengisi data sumber air limbah",
      //       icon: "error",
      //       })
      //     } else if (!this.permissionLaterFormExistingSelected && !this.form.teknis.wasteSource) {
      //       Swal.fire({
      //       title: "Nomor Surat dan Sumber Air Limbah wajib di isi",
      //       text: "Pastikan anda melengkapi data",
      //       icon: "error",
      //       })
      //     } else {
      //       let headers = {
      //         Authorization: `Bearer ${localStorage.getItem("token")}`,
      //       };


      //       API.post(`sparing`, payload, { headers })
      //       .then(({ data: content }) => {
      //         console.log(content,'BERHASIL');
      //         this.newPayloadTmp = []
      //         this.$router.push('/sparing')
      //           Swal.fire({
      //           title: "Berhasil Mendaftar",
      //           text: "Admin SPARING akan meninjau permintaan pendaftaran SPARING anda",
      //           icon: "success",
      //         });

      //       })
      //       .catch((err) => {
      //         console.log(err);
      //         Swal.fire("Pendaftaran Gagal", "gagal mendaftarkan", "error");
      //       });
      //     }
      //   }
      // });

    },
    resetForm() {
          this.getStationForUpdate = ""
          this.titikPenaatanPopUpForm.namaTitikPenaatan = ""
          // this.form.teknis.wasteSource = ""
          this.titikPenaatanPopUpForm.namaTitikPenaatan = ""
          this.titikPenaatanPopUpForm.lintang = ""
          this.titikPenaatanPopUpForm.bujur = ""
          this.titikPenaatanPopUpForm.das = ""
          this.form.teknis.processingTechnique = ""
          this.form.teknis.wasteType = ""
          this.form.teknis.debit = ""
          this.form.teknis.satuanDebit = ""
          this.form.teknis.capacityPermit = ""
          this.form.teknis.capacityActual = ""
          this.form.teknis.frequenceDet = ""
          this.form.frekuensi.jam = ""
          this.form.frekuensi.minggu = ""
          this.form.frekuensi.bulan = ""
          this.form.frekuensi.latarBelakang = ""
          this.form.frekuensi.detEngIPAL = ""
          this.form.frekuensi.metUkurDebit = ""
          this.loggerSensorPopUpForm.lulusUji = ""
          this.loggerSensorPopUpForm.noSurat = ""
          this.loggerSensorPopUpForm.namaPenyedia = ""
          this.loggerSensorPopUpForm.infoAlat = ""
          this.loggerSensorPopUpForm.brand = ""
          this.loggerSensorPopUpForm.model = ""
          this.loggerSensorPopUpForm.mac = ""
          this.loggerSensorPopUpForm.sn = ""
          this.form.sensor = [
          {
            name: "pH",
            label: "pH",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
          {
            name: "COD",
            label: "COD",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
          {
            name: "TSS",
            label: "TSS",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
          {
            name: "NH3N",
            label: "NH3N",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
          {
            name: "Debit",
            label: "Debit",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: 0,
            max: 0,
            minMeasure: 0,
            maxMeasure: 0,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
            satuanDebit: "",
          },
        ]
    },
    showFormTitikPenataanModal() {
      this.showFormPenataanFormModal = true;
      this.resetForm()
    },
    willDeleteStation(row) {
      Swal.fire({
        title: `Hapus Titik Penaatan ${row.namaTitikPenaatan} ?`,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#29664A",
        cancelButtonColor: "#d33",
        confirmButtonText: "HAPUS",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let indexToDelete = this.newPayloadTmp.findIndex(
            (tableRow) => tableRow.namaTitikPenaatan === row.namaTitikPenaatan
          );
          if (indexToDelete >= 0) {
            this.newPayloadTmp.splice(indexToDelete, 1);
          }
    
          this.resetForm()
          this.showFormPenataanFormModal = false;

          Swal.fire({
            title: "Berhasil Menghapus",
            text: "Titik Penaatan telah dihapus dari Pendaftaran SPARING",
            icon: "success",
          });
        }
      });
    },
    // NEW
    getLoggerRegistered(id) {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get(
        `companies/sparings/compliance-points/loggers?nama_titik_penaatan=${id}`,
        { headers }
      )
        .then(({ data: content }) => {
          console.log(content.data, "uhuuu");
          this.loggerRegistered = content.data;
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(
            "Pencarian data gagal",
            "harap periksa koneksi internet anda",
            "error"
          );
        });
    },
    deleteLoggerRegistered(row) {
      Swal.fire({
        title: "Hapus Logger ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "HAPUS",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          API.delete(
            `companies/sparings/compliance-points/detail/${row.detail_id}`,
            { headers }
          )
            .then(({ data }) => {
              Swal.fire({
                title: "Berhasil Menghapus",
                text: "Logger telah dihapus dari Pendaftaran SPARING",
                icon: "success",
              });

              this.getListDataTitikPenaatanForCreateLogger();
            })
            .catch((err) => {
              Swal.fire("Gagal Menghapus", "", "error");
            });
        }
      });
    },
    actionUpdateLoggerRegistered(row) {
      Swal.fire({
        title: "Perbaharui Logger ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "PERBAHARUI",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loggerFormInput.append("id", row);
          this.loggerFormInput.append(
            "info_alat",
            this.loggerSensorPopUpForm.infoAlat
          );
          this.loggerFormInput.append(
            "logger_brand",
            this.loggerSensorPopUpForm.brand
          );
          this.loggerFormInput.append(
            "logger_mac_address",
            this.loggerSensorPopUpForm.mac
          );
          this.loggerFormInput.append(
            "logger_model",
            this.loggerSensorPopUpForm.model
          );
          this.loggerFormInput.append(
            "logger_serial_number",
            this.loggerSensorPopUpForm.sn
          );
          this.loggerFormInput.append(
            "lulus_uji_konektivitas",
            this.loggerSensorPopUpForm.lulusUji
          );
          this.loggerFormInput.append(
            "sparing_compliance_point_id",
            this.loggerSensorIndex.id
          );
          this.loggerFormInput.append(
            "nama_penyedia_barang_dan_jasa",
            this.loggerSensorPopUpForm.namaPenyedia
          );
          this.loggerFormInput.append(
            "nomor_surat_lulus_uji_konektivitas",
            this.loggerSensorPopUpForm.noSurat
          );

          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          API.put(
            "companies/sparings/compliance-points/detail",
            this.loggerFormInput,
            { headers }
          )
            .then(({ data }) => {
              Swal.fire(
                "Logger Berhasil diperbaharui",
                "Data Berhasil didaftarkan",
                "success"
              );

              this.getListDataTitikPenaatanForCreateLogger();

              this.loggerFormInput = new FormData();
              this.loggerSensorPopUpForm = {
                brand: "",
                model: "",
                sn: "",
                mac: "",
                lulusUji: "",
                infoAlat: "",
                namaPenyedia: "",
                noSurat: "",
                suratketerangan: "",
              };

              this.loggerFormInput = new FormData();

              this.showFormLoggerSensorFormModal = false;

              this.fileSuratKeteranganLulusUji++;
              this.fileHasilKalibrasiSaatAwalPemasanganAlat++;
              this.fileHasilKalibrasiAlatRutin++;
              this.fileHasilUjiKinerjaDariLabotarium++;
              this.fileSuratDukunganBrandSensor++;
              this.fileSuratPernyataan++;
              this.fileKontakKerjaSama++;
            })
            .catch((err) => {
              console.log(err);
              Swal.fire("Pendaftaran Gagal", "terjadi kesalahan", "error");
            });
        }
      });
    },
    cancelUpdateLogger() {},
    pendaftaranRubahSensor(row) {
      this.pendaftaranRubahSensorID = row.id;
      this.rubahSensorPendaftaran = true;
      this.form.sensor[0].active = false;
      this.form.sensor[1].active = false;
      this.form.sensor[2].active = false;
      this.form.sensor[3].active = false;
      this.form.sensor[4].active = false;
      let sensorName = "";

      // reset - nanti akan dibuat clean code
      this.form.sensor[0].brand = "";
      this.form.sensor[0].probe = "";
      this.form.sensor[0].minMeasure = "";
      this.form.sensor[0].maxMeasure = "";
      this.form.sensor[0].min = "";
      this.form.sensor[0].max = "";
      this.form.sensor[0].metodePengukuran = "";
      this.form.sensor[0].jadwalKalibrasi = "";
      this.form.sensor[0].tanggalKalibrasi = "";
      this.form.sensor[0].metodeKalibrasi = "";
      this.form.sensor[0].satuanDebit = "";
      // 2
      this.form.sensor[1].brand = "";
      this.form.sensor[1].probe = "";
      this.form.sensor[1].minMeasure = "";
      this.form.sensor[1].maxMeasure = "";
      this.form.sensor[1].min = "";
      this.form.sensor[1].max = "";
      this.form.sensor[1].metodePengukuran = "";
      this.form.sensor[1].jadwalKalibrasi = "";
      this.form.sensor[1].tanggalKalibrasi = "";
      this.form.sensor[1].metodeKalibrasi = "";
      this.form.sensor[1].satuanDebit = "";
      // 3
      this.form.sensor[2].brand = "";
      this.form.sensor[2].probe = "";
      this.form.sensor[2].minMeasure = "";
      this.form.sensor[2].maxMeasure = "";
      this.form.sensor[2].min = "";
      this.form.sensor[2].max = "";
      this.form.sensor[2].metodePengukuran = "";
      this.form.sensor[2].jadwalKalibrasi = "";
      this.form.sensor[2].tanggalKalibrasi = "";
      this.form.sensor[2].metodeKalibrasi = "";
      this.form.sensor[2].satuanDebit = "";
      // 4
      this.form.sensor[3].brand = "";
      this.form.sensor[3].probe = "";
      this.form.sensor[3].minMeasure = "";
      this.form.sensor[3].maxMeasure = "";
      this.form.sensor[3].min = "";
      this.form.sensor[3].max = "";
      this.form.sensor[3].metodePengukuran = "";
      this.form.sensor[3].jadwalKalibrasi = "";
      this.form.sensor[3].tanggalKalibrasi = "";
      this.form.sensor[3].metodeKalibrasi = "";
      this.form.sensor[3].satuanDebit = "";
      // 5
      this.form.sensor[4].brand = "";
      this.form.sensor[4].probe = "";
      this.form.sensor[4].minMeasure = "";
      this.form.sensor[4].maxMeasure = "";
      this.form.sensor[4].min = "";
      this.form.sensor[4].max = "";
      this.form.sensor[4].metodePengukuran = "";
      this.form.sensor[4].jadwalKalibrasi = "";
      this.form.sensor[4].tanggalKalibrasi = "";
      this.form.sensor[4].metodeKalibrasi = "";
      this.form.sensor[4].satuanDebit = "";
      this.populatedBrosurExisting = "";

      // reset - nanti akan dibuat clean code

      if (row.sensor_type == "pH") {
        sensorName = "0";
      } else if (row.sensor_type == "COD") {
        sensorName = "1";
      } else if (row.sensor_type == "TSS") {
        sensorName = "2";
      } else if (row.sensor_type == "NH3N") {
        sensorName = "3";
      } else if (row.sensor_type == "Debit") {
        sensorName = "4";
      }

      console.log(row, "-------------->");
      console.log(sensorName, "-------------->");

      this.getFormSensor = sensorName;

      this.form.sensor[sensorName].active = true;
      this.form.sensor[sensorName].brand = row.brand;
      this.form.sensor[sensorName].probe = row.probe;
      this.form.sensor[sensorName].minMeasure = row.range_min;
      this.form.sensor[sensorName].maxMeasure = row.range_max;
      // baku mutu
      this.form.sensor[sensorName].min = row.bmal_min;
      this.form.sensor[sensorName].max = row.bmal_max;
      this.form.sensor[sensorName].metodePengukuran = row.metode_pengukuran;
      this.form.sensor[sensorName].jadwalKalibrasi = row.jadwal_kalibrasi;
      this.form.sensor[sensorName].tanggalKalibrasi = row.tanggal_kalibrasi;
      this.form.sensor[sensorName].metodeKalibrasi = row.metode_kalibrasi;
      this.form.sensor[sensorName].satuanDebit = row.satuanDebit;
      this.populatedBrosurExisting = row.brosur_path;
    },
    pendaftaranHapusSensor(row) {
      Swal.fire({
        title: "Hapus Sensor ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "HAPUS",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          API.delete(`companies/sparings/compliance-points/sensor/${row.id}`, {
            headers,
          })
            .then(({ data }) => {
              Swal.fire({
                title: "Berhasil Menghapus",
                text: "Sensor telah dihapus dari Titik Penaatan ini",
                icon: "success",
              });

              this.form.sensor[0].active = false;
              this.form.sensor[1].active = false;
              this.form.sensor[2].active = false;
              this.form.sensor[3].active = false;
              this.form.sensor[4].active = false;

              this.fillDataSensor(this.idForAddSensorNew);
            })
            .catch((err) => {
              Swal.fire("Gagal Menghapus", "", "error");
            });
        }
      });
    },
    closeCreatePermissionLaterWithExistingPermission() {
      this.permissionLaterFormExistingSelected = "";
      this.showFormModalWithExistPermission = false;
    },
    goToLastWizard() {
      Swal.fire({
        title: "Lanjut Mengisi Logger ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "LANJUT",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          this.showSecondaryWizardForm = false;

          setTimeout(() => {
            this.showThirdWizardForm = true;
          }, 1000);
        }
      });
    },
    goToFinishWizard() {
      Swal.fire({
        title: "Apakah Anda Yakin?",
        text: "Jika Menekan selesai , seluruh pendaftaran SPARING sudah dinyatakan selesai",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "SELESAI",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            "Pendaftaran Selesai",
            "jika ada yang terlewatkan , anda dapat merubahnya di halaman depan Sparing",
            "success"
          );
          this.showThirdWizardForm = false;
        }
      });
    },
    // Function to get old master permit for reuse again to register
    getDataForOldLater() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get("permits", { headers })
        .then(({ data: content }) => {
          if (content.data == null) {
            this.permissionLaterFormExisting = [];
          } else {
            this.permissionLaterFormExisting = content.data;
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(
            "Pencarian data gagal",
            "harap periksa koneksi internet anda",
            "error"
          );
        });
    },
    // Function to create new master permit
    createPermissionLater() {

      if (!this.form.teknis.permitDateExpire || !this.form.teknis.permitPerson || !this.form.teknis.permitNumber || !this.form.teknis.permitDatePublish) {
        return Swal.fire("Data belum lengkap",'Pastikan anda melengkapi semua form','error')
      }

      Swal.fire({
        title: "Menambahkan Surat Izin?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "TAMBAH",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          let payload = {
            attachment_file_id: this.form.teknis.sparing_air_limbah_file,
            expiration_date: this.form.teknis.permitDateExpire,
            issuing_authority: this.form.teknis.permitPerson,
            no: this.form.teknis.permitNumber,
            publication_date: this.form.teknis.permitDatePublish
          }
         
          API.post("permits", payload, {headers})
            .then(({ data }) => {
              this.form.teknis.permitDateExpire = ""
              this.form.teknis.permitPerson = ""
              this.form.teknis.permitNumber = ""
              this.form.teknis.permitDatePublish = ""

              this.getDataForOldLater()
              this.showFormModal = false;
              Swal.fire("Surat izin ditambahkan","pendaftaran surat izin berhasil","success");

            })
            .catch((err) => {
              console.log(err, "ini error");
              Swal.fire("Pendaftaran Gagal","harap telah mengisi semua data atau periksa koneksi internet anda","error");
            });
            }
          });
    },
    // Function to create permit using id return function getDataForOldLater
    createPermissionLaterWithExistingPermission() {
      console.log(this.permissionLaterFormExistingSelected);
      this.showFormModalWithExistPermission = false;

      if (this.permissionLaterFormExistingSelected) {
        this.form.uid = this.permissionLaterFormExistingSelected;
        // go to new modal
        setTimeout(() => {
          this.permissionLaterFormExistingSelected = "";
          this.showMainWizardForm = true;
        }, 500);
      }
    },
    /**
     * 1. Submit Data titik penaatan Wizard 1 (submitDataTitikPenaatan)
     * 2. Submit Detail titik penaatan Wizard 2 (submitDataTitikPenaatanPlace)
     * 3. Submit Logger Wizard 3 (submitLogger)
     * 4. Submit Sensor Wizard 4 (submitSensor)
     */
    submitDataTitikPenaatan() {
      this.formDataSparing.append("debit", this.form.teknis.debit);
      this.formDataSparing.append(
        "frekuensi_pembuangan_air_limbah",
        this.form.teknis.frequenceDet
      );
      this.formDataSparing.append(
        "frekuensi_per_hari",
        this.form.frekuensi.jam
      );
      this.formDataSparing.append(
        "frekuensi_per_minggu",
        this.form.frekuensi.hari
      );
      this.formDataSparing.append(
        "frekuensi_per_tahun",
        this.form.frekuensi.bulan
      );
      this.formDataSparing.append(
        "jenis_air_limbah",
        this.form.teknis.wasteType
      );
      this.formDataSparing.append(
        "jenis_teknologi_pengolahan_air_limbah",
        this.form.teknis.processingTechnique
      );
      this.formDataSparing.append(
        "kapasitas_produksi_senyatanya",
        this.form.teknis.capacityPermit
      );
      this.formDataSparing.append(
        "kapasitas_produksi_sesuai_izin",
        this.form.teknis.capacityActual
      );
      this.formDataSparing.append("sparing_permit_id", this.form.uid);
      this.formDataSparing.append(
        "sumber_air_limbah",
        this.form.teknis.wasteSource
      );
      this.formDataSparing.append(
        "frekuensi_detail_engineering_ipal",
        this.form.frekuensi.detEngIPAL
      );
      this.formDataSparing.append(
        "frekuensi_latar_belakang",
        this.form.frekuensi.latarBelakang
      );
      this.formDataSparing.append(
        "frekuensi_metode_pengukuran_debit",
        this.form.frekuensi.metUkurDebit
      );

      Swal.fire({
        title: "Tambah Pendaftaran Sparing ?",
        text: "Jika Ya , anda akan melanjutkan ke pengisian data lainnya",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "TAMBAH",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          API.post("companies/sparings", this.formDataSparing, { headers })
            .then(({ data }) => {
              this.getReturnedIdCreated = data.data.id;
              Swal.fire(
                "Pendaftaran Berhasil",
                "Lanjut mengisi titik penaatan",
                "success"
              );
              this.showMainWizardForm = false;
              this.resetPayloadDataTitikPenaatan();
              setTimeout(() => {
                this.showMainWizardPointForm = true;
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
              Swal.fire("Pendaftaran Gagal", "terjadi kesalahan", "error");
            });
        }
      });
    },
    submitDataTitikPenaatanPlace() {
      let result_number_compliance_point_data = [];
      for (let i = 0; i < this.form.teknis.spotList.length; i++) {
        result_number_compliance_point_data.push({
          bujur: this.form.teknis.spotList[i].spotLoc[1],
          das: this.form.teknis.spotList[i].das,
          lintang: this.form.teknis.spotList[i].spotLoc[0],
          nama_badan_air_penerima_air_limbah:
            this.form.teknis.spotList[i].penerimaAirLimbah,
          nama_titik_penaatan: this.form.teknis.spotList[i].spotName,
          sparing_id: this.getReturnedIdCreated,
        });
      }

      let payload = result_number_compliance_point_data;

      Swal.fire({
        title: "Lanjut Mengisi Sensor ?",
        text: "Jika Ya , anda hanya dapat memperbaharui data logger lewat menu Rubah Data",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "LANJUT",
        cancelButtonText: "BATAL",
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          API.post("companies/sparings/compliance-points", payload, { headers })
            .then(({ data }) => {
              Swal.fire(
                "Titik Penaatan Berhasil Ditambahkan",
                "Lanjut mengisi Logger setiap titik penaatan",
                "success"
              );
              this.resetPayloadDataTitikPenaatan();
              // this.getLoggerRegistered()
              this.showMainWizardPointForm = false;

              setTimeout(() => {
                this.getListDataTitikPenaatanForCreateLogger();
                this.showSecondaryWizardForm = true;
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
              Swal.fire(
                "Pendaftaran Titik penaatan Gagal",
                "terjadi kesalahan",
                "error"
              );
            });
        }
      });
    },
    getListDataTitikPenaatanForCreateLogger() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get(
        `companies/sparings/compliance-points/loggers?sparing_id=${this.getReturnedIdCreated}`,
        { headers }
      )
        .then(({ data: content }) => {
          console.log(content.data, "yyyyyyyyyyyyyyyyy");
          this.getLoggerHasSubmit = content.data;
        })
        .catch((err) => {
          Swal.fire("Gagal mendapatkan data", "terjadi kesalahan", "error");
        });
    },
    submitLogger() {
      this.loggerFormInput.append(
        "info_alat",
        this.loggerSensorPopUpForm.infoAlat
      );
      this.loggerFormInput.append(
        "logger_brand",
        this.loggerSensorPopUpForm.brand
      );
      this.loggerFormInput.append(
        "logger_mac_address",
        this.loggerSensorPopUpForm.mac
      );
      this.loggerFormInput.append(
        "logger_model",
        this.loggerSensorPopUpForm.model
      );
      this.loggerFormInput.append(
        "logger_serial_number",
        this.loggerSensorPopUpForm.sn
      );
      this.loggerFormInput.append(
        "lulus_uji_konektivitas",
        this.loggerSensorPopUpForm.lulusUji
      );
      this.loggerFormInput.append(
        "sparing_compliance_point_id",
        this.loggerSensorIndex.id
      );

      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.post(
        "companies/sparings/compliance-points/detail",
        this.loggerFormInput,
        { headers }
      )
        .then(({ data }) => {
          Swal.fire(
            "Logger Berhasil ditambah",
            "Data Berhasil didaftarkan",
            "success"
          );

          this.loggerFormInput = new FormData();

          this.loggerSensorPopUpForm = {
            brand: "",
            model: "",
            sn: "",
            mac: "",
            lulusUji: "",
            infoAlat: "",
            namaPenyedia: "",
            noSurat: "",
            suratketerangan: "",
          };

          this.loggerFormInput = new FormData();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire("Pendaftaran Gagal", "terjadi kesalahan", "error");
        });
    },
    uploadSubmitLogger(category, field) {
      this.loggerFormInput.append(field, this.$refs[field].files[0]);
    },
    submitSensor() {
      this.sensorFormInput = new FormData();
      console.log("do");

      if (this.rubahSensorPendaftaran) {
        // PUT

        let sensorName = "";

        if (this.getFormSensor == 0) {
          sensorName = "pH";
        } else if (this.getFormSensor == 1) {
          sensorName = "COD";
        } else if (this.getFormSensor == 2) {
          sensorName = "TSS";
        } else if (this.getFormSensor == 3) {
          sensorName = "NH3N";
        } else if (this.getFormSensor == 4) {
          sensorName = "Debit";
        }

        console.log(this.getFormSensor, "getFormSensor");
        console.log(this.form.sensor[this.getFormSensor], "ALL");

        this.sensorFormInput.append(
          "bmal_max",
          this.form.sensor[this.getFormSensor].max
        );
        this.sensorFormInput.append(
          "bmal_min",
          this.form.sensor[this.getFormSensor].min
        );
        this.sensorFormInput.append(
          "brand",
          this.form.sensor[this.getFormSensor].brand
        );
        this.sensorFormInput.append("id", this.pendaftaranRubahSensorID);
        this.sensorFormInput.append(
          "jadwal_kalibrasi",
          this.form.sensor[this.getFormSensor].jadwalKalibrasi
        );
        this.sensorFormInput.append(
          "metode_kalibrasi",
          this.form.sensor[this.getFormSensor].metodeKalibrasi
        );
        this.sensorFormInput.append(
          "metode_pengukuran",
          this.form.sensor[this.getFormSensor].metodePengukuran
        );
        this.sensorFormInput.append(
          "probe",
          this.form.sensor[this.getFormSensor].probe
        );
        this.sensorFormInput.append(
          "range_max",
          this.form.sensor[this.getFormSensor].maxMeasure
        );
        this.sensorFormInput.append(
          "range_min",
          this.form.sensor[this.getFormSensor].minMeasure
        );
        this.sensorFormInput.append(
          "tanggal_kalibrasi",
          this.form.sensor[this.getFormSensor].tanggalKalibrasi
        );

        if (sensorName == "Debit") {
          this.sensorFormInput.append(
            "bmal_unit",
            this.form.sensor[this.getFormSensor].satuanDebit
          );
        }

        Swal.fire({
          title: "Merubah Data Sensor ?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "RUBAH",
          cancelButtonText: "BATAL",
        }).then((result) => {
          if (result.isConfirmed) {
            let headers = {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            };

            API.put(
              "companies/sparings/compliance-points/sensor",
              this.sensorFormInput,
              { headers }
            )
              .then(({ data }) => {
                // ini rubah dinamis
                this.fillDataSensor(this.idForAddSensorNew);
                Swal.fire("Sensor Berhasil diperbaharui", "", "success");
                this.resetPayloadSensor();
              })
              .catch((err) => {
                Swal.fire("Gagal Memperbaharui", "terjadi kesalahan", "error");
                // paksa reset data update
              });

            this.fileBrosur++;
            this.rubahSensorPendaftaran = false;
          }
        });
      } else {
        // POST

        let sensorName = "";

        if (this.getFormSensor == 0) {
          sensorName = "pH";
        } else if (this.getFormSensor == 1) {
          sensorName = "COD";
        } else if (this.getFormSensor == 2) {
          sensorName = "TSS";
        } else if (this.getFormSensor == 3) {
          sensorName = "NH3N";
        } else if (this.getFormSensor == 4) {
          sensorName = "Debit";
        }

        this.sensorFormInput.append(
          "bmal_max",
          this.form.sensor[this.getFormSensor].max
        );
        this.sensorFormInput.append(
          "bmal_min",
          this.form.sensor[this.getFormSensor].min
        );
        this.sensorFormInput.append(
          "brand",
          this.form.sensor[this.getFormSensor].brand
        );
        this.sensorFormInput.append(
          "jadwal_kalibrasi",
          this.form.sensor[this.getFormSensor].jadwalKalibrasi
        );
        this.sensorFormInput.append(
          "metode_kalibrasi",
          this.form.sensor[this.getFormSensor].metodeKalibrasi
        );
        this.sensorFormInput.append(
          "metode_pengukuran",
          this.form.sensor[this.getFormSensor].metodePengukuran
        );
        this.sensorFormInput.append(
          "probe",
          this.form.sensor[this.getFormSensor].probe
        );
        this.sensorFormInput.append(
          "range_max",
          this.form.sensor[this.getFormSensor].maxMeasure
        );
        this.sensorFormInput.append(
          "range_min",
          this.form.sensor[this.getFormSensor].minMeasure
        );
        this.sensorFormInput.append("sensor_type", sensorName);
        this.sensorFormInput.append(
          "sparing_compliance_point_id",
          this.loggerSensorIndex.id
        );
        this.sensorFormInput.append(
          "tanggal_kalibrasi",
          this.form.sensor[this.getFormSensor].tanggalKalibrasi
        );

        if (sensorName == "Debit") {
          this.sensorFormInput.append(
            "bmal_unit",
            this.form.sensor[this.getFormSensor].satuanDebit
          );
        }

        Swal.fire({
          title: "Tambahkan Sensor ?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "TAMBAH",
          cancelButtonText: "BATAL",
        }).then((result) => {
          if (result.isConfirmed) {
            let headers = {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            };

            API.post(
              "companies/sparings/compliance-points/sensor",
              this.sensorFormInput,
              { headers }
            )
              .then(({ data }) => {
                // ini rubah dinamis
                this.fillDataSensor(this.idForAddSensorNew);
                Swal.fire(
                  "Sensor Berhasil ditambahkan",
                  "Data Berhasil didaftarkan",
                  "success"
                );
                this.resetPayloadSensor();
              })
              .catch((err) => {
                Swal.fire("Pendaftaran Gagal", "terjadi kesalahan", "error");
              });

            this.fileBrosur++;
          }
        });
      }
    },
    closeSubmitSensor() {
      this.sensorTmpData = [];
      this.showFormLoggerSensorConnectingFormModal = false;
    },
    uploadDataTitikPenaatan(category, field) {
      this.formDataSparing.append(field, this.$refs[field].files[0]);
    },

    fillDataLogger(row) {
      console.log(row);

      if (row.logger_id == null) {
        // create new
        this.loggerSensorPopUpForm.infoAlat = "";
        this.loggerSensorPopUpForm.brand = "";
        this.loggerSensorPopUpForm.mac = "";
        this.loggerSensorPopUpForm.model = "";
        this.loggerSensorPopUpForm.sn = "";
        this.loggerSensorPopUpForm.lulusUji = "";
        this.loggerSensorIndex.id = "";
        this.loggerSensorPopUpForm.namaPenyedia = "";
        this.loggerSensorPopUpForm.noSurat = "";
        this.editLogger = false;
        this.editLoggerID = "";
      } else {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        API.get(
          `companies/sparings/compliance-points/detail/${row.detail_id}`,
          { headers }
        )
          .then(({ data: content }) => {
            this.loggerSensorPopUpForm.infoAlat = content.data.info_alat;
            this.loggerSensorPopUpForm.brand = content.data.logger_brand;
            this.loggerSensorPopUpForm.mac = content.data.logger_mac_address;
            this.loggerSensorPopUpForm.model = content.data.logger_model;
            this.loggerSensorPopUpForm.sn = content.data.logger_serial_number;
            this.loggerSensorPopUpForm.lulusUji =
              content.data.lulus_uji_konektivitas;
            this.loggerSensorIndex.id = row.detail_id;
            this.loggerSensorPopUpForm.namaPenyedia =
              content.data.nama_penyedia_barang_dan_jasa;
            this.loggerSensorPopUpForm.noSurat =
              content.data.nomor_surat_lulus_uji_konektivitas;
            this.editLogger = true;
            this.editLoggerID = row.detail_id;
          })
          .catch((err) => {
            console.log(err);
            Swal.fire(
              "Pencarian data gagal",
              "harap periksa koneksi internet anda",
              "error"
            );
          });
      }

      this.getLoggerRegistered(row.id);
      this.loggerFormInput = new FormData();

      this.loggerSensorIndex = row;
      this.showFormLoggerSensorFormModal = true;
    },
    fillDataSensor(row) {
      this.loggerSensorIndex = row;

      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      this.idForAddSensorNew = row;

      API.get(
        `companies/sparings/compliance-points/sensor?sparing_compliance_point_id=${row.id}`,
        { headers }
      )
        .then(({ data: content }) => {
          if (content.data == null) {
            this.populatedSensorExisting = [];
          } else {
            this.populatedSensorExisting = content.data;
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(
            "Pencarian data gagal",
            "harap periksa koneksi internet anda",
            "error"
          );
        });
      this.showFormLoggerSensorConnectingFormModal = true;
    },
    resetPayloadDataTitikPenaatan() {
      this.form.teknis.debit = "";
      this.form.teknis.frequenceDet = "";
      this.form.teknis.wasteType = "";
      this.form.teknis.processingTechnique = "";
      this.form.teknis.capacityPermit = "";
      this.form.teknis.capacityActual = "";
      this.form.uid = "";
      this.form.teknis.wasteSource = "";
      this.form.teknis.spotList = [];
    },
    resetPayloadSensor() {
      this.sensorFormInput = new FormData();
      this.form.sensor = [
        {
          name: "pH",
          label: "pH",
          active: false,
          brand: null,
          probe: null,
          brosurFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          min: 0,
          max: 0,
          minMeasure: 0,
          maxMeasure: 0,
          metodePengukuran: null,
          jadwalKalibrasi: null,
          tanggalKalibrasi: new Date(),
          metodeKalibrasi: null,
        },
        {
          name: "COD",
          label: "COD",
          active: false,
          brand: null,
          probe: null,
          brosurFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          min: 0,
          max: 0,
          minMeasure: 0,
          maxMeasure: 0,
          metodePengukuran: null,
          jadwalKalibrasi: null,
          tanggalKalibrasi: new Date(),
          metodeKalibrasi: null,
        },
        {
          name: "TSS",
          label: "TSS",
          active: false,
          brand: null,
          probe: null,
          brosurFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          min: 0,
          max: 0,
          minMeasure: 0,
          maxMeasure: 0,
          metodePengukuran: null,
          jadwalKalibrasi: null,
          tanggalKalibrasi: new Date(),
          metodeKalibrasi: null,
        },
        {
          name: "NH3N",
          label: "NH3N",
          active: false,
          brand: null,
          probe: null,
          brosurFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          min: 0,
          max: 0,
          minMeasure: 0,
          maxMeasure: 0,
          metodePengukuran: null,
          jadwalKalibrasi: null,
          tanggalKalibrasi: new Date(),
          metodeKalibrasi: null,
        },
        {
          name: "Debit",
          label: "Debit",
          active: false,
          brand: null,
          probe: null,
          brosurFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          min: 0,
          max: 0,
          minMeasure: 0,
          maxMeasure: 0,
          metodePengukuran: null,
          jadwalKalibrasi: null,
          tanggalKalibrasi: new Date(),
          metodeKalibrasi: null,
        },
      ];
    },

    showSensorModal(index) {
      this.rubahSensorPendaftaran = false;
      this.form.sensor[0].active = false;
      this.form.sensor[1].active = false;
      this.form.sensor[2].active = false;
      this.form.sensor[3].active = false;
      this.form.sensor[4].active = false;

      console.log("clicked");
      console.log(index, "--------------?s");
      this.form.sensor[index].active = true;
      this.indexSensor = index;

      // reset
      this.form.sensor[index].brand = "";
      this.form.sensor[index].probe = "";
      this.form.sensor[index].minMeasure = "";
      this.form.sensor[index].maxMeasure = "";
      // baku mutu
      this.form.sensor[index].min = "";
      this.form.sensor[index].max = "";
      this.form.sensor[index].metodePengukuran = "";
      this.form.sensor[index].jadwalKalibrasi = "";
      this.form.sensor[index].tanggalKalibrasi = "";
      this.form.sensor[index].metodeKalibrasi = "";
      this.form.sensor[index].satuanDebit = "";
      this.populatedBrosurExisting = "";
    },

    // routing
    showNewFormForRegister() {
      this.showFormModal = true;
    },
    goBack() {
      this.$router.push("/sparing");
    },

    async showOldFormForRegister() {
      try {
        this.getDataForOldLater();
        this.showFormModalWithExistPermission = true;
      } catch (error) {
        console.log(error);
      }
    },
    // table method
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    // delete temporary data titik penaatan
    
    // fill temporary data logger dan sensor

    // edit temporary data titik penaatan
    willEdit(row) {
      console.log(row);
      let indexToEdit = this.form.teknis.spotList.findIndex(
        (tableRow) => tableRow.spotName === row.spotName
      );

      this.titikPenaatanPopUpEditForm.id = indexToEdit;
      this.titikPenaatanPopUpEditForm.spotName =
        this.form.teknis.spotList[indexToEdit].spotName;
      this.titikPenaatanPopUpEditForm.spotLoc =
        this.form.teknis.spotList[indexToEdit].spotLoc;
      this.titikPenaatanPopUpEditForm.das =
        this.form.teknis.spotList[indexToEdit].das;
      this.titikPenaatanPopUpEditForm.penerimaAirLimbah =
        this.form.teknis.spotList[indexToEdit].penerimaAirLimbah;
      this.showUpdateFormPenataanFormModal = true;
    },
    updateFormPenaatanModal() {
      this.showUpdateFormPenataanFormModal = false;
      this.form.teknis.spotList[this.titikPenaatanPopUpEditForm.id].spotName =
        this.titikPenaatanPopUpEditForm.spotName;
      this.form.teknis.spotList[this.titikPenaatanPopUpEditForm.id].spotLoc =
        this.titikPenaatanPopUpEditForm.spotLoc;
      this.form.teknis.spotList[this.titikPenaatanPopUpEditForm.id].das =
        this.titikPenaatanPopUpEditForm.das;
      this.form.teknis.spotList[
        this.titikPenaatanPopUpEditForm.id
      ].penerimaAirLimbah = this.titikPenaatanPopUpEditForm.penerimaAirLimbah;
    },
    // tambah titik penaatan
    tambahDataPenataan() {
      this.showFormPenataanFormModal = false;
      console.log("add penataan");
      this.form.teknis.spotList.push({
        spotName: this.titikPenaatanPopUpForm.namaTitikPenaatan,
        spotLoc: [
          this.titikPenaatanPopUpForm.lintang,
          this.titikPenaatanPopUpForm.bujur,
        ],
        penerimaAirLimbah: this.titikPenaatanPopUpForm.penerimaAirLimbah,
        das: this.titikPenaatanPopUpForm.das,
        // explore
        brand: "?",
        model: "?",
        sn: "?",
        mac: "?",
        // persyaratann teknis here
        infoAlat: null,
        lulusUji: null,
        namaPenyedia: null,
        noSurat: null,
        // disini buat sensor nanti
        sensor: [
          {
            name: "pH",
            label: "pH",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: null,
            max: null,
            minMeasure: null,
            maxMeasure: null,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
          },
          {
            name: "COD",
            label: "COD",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: null,
            max: null,
            minMeasure: null,
            maxMeasure: null,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
          },
          {
            name: "TSS",
            label: "TSS",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: null,
            max: null,
            minMeasure: null,
            maxMeasure: null,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
          },
          {
            name: "NH3N",
            label: "NH3N",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: null,
            max: null,
            minMeasure: null,
            maxMeasure: null,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
          },
          {
            name: "Debit",
            label: "Debit",
            active: false,
            brand: null,
            probe: null,
            brosurFile: {
              path: "",
              upload: 0,
              progress: 0,
            },
            min: null,
            max: null,
            minMeasure: null,
            maxMeasure: null,
            metodePengukuran: null,
            jadwalKalibrasi: null,
            tanggalKalibrasi: new Date(),
            metodeKalibrasi: null,
          },
        ],
      });

      this.titikPenaatanPopUpForm.namaTitikPenaatan = "";
      this.titikPenaatanPopUpForm.lintang = "";
      this.titikPenaatanPopUpForm.bujur = "";
      this.titikPenaatanPopUpForm.penerimaAirLimbah = "";
      this.titikPenaatanPopUpForm.das = "";
    },
    // tambah logger sensor
    tambahDataLoggerSensor() {
      // this.submitLogger()

      if (this.editLogger) {
        // edit
        this.actionUpdateLoggerRegistered(this.editLoggerID);
        console.log("EDIT BOS");

        this.editLogger = false;
        this.editLoggerID = "";
      } else {
        // createNew

        Swal.fire({
          title: "Tambah Logger",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "TAMBAH",
          cancelButtonText: "BATAL",
        }).then((result) => {
          if (result.isConfirmed) {
            this.loggerFormInput.append(
              "info_alat",
              this.loggerSensorPopUpForm.infoAlat
            );
            this.loggerFormInput.append(
              "logger_brand",
              this.loggerSensorPopUpForm.brand
            );
            this.loggerFormInput.append(
              "logger_mac_address",
              this.loggerSensorPopUpForm.mac
            );
            this.loggerFormInput.append(
              "logger_model",
              this.loggerSensorPopUpForm.model
            );
            this.loggerFormInput.append(
              "logger_serial_number",
              this.loggerSensorPopUpForm.sn
            );
            this.loggerFormInput.append(
              "lulus_uji_konektivitas",
              this.loggerSensorPopUpForm.lulusUji
            );
            this.loggerFormInput.append(
              "sparing_compliance_point_id",
              this.loggerSensorIndex.id
            );
            this.loggerFormInput.append(
              "nama_penyedia_barang_dan_jasa",
              this.loggerSensorPopUpForm.namaPenyedia
            );
            this.loggerFormInput.append(
              "nomor_surat_lulus_uji_konektivitas",
              this.loggerSensorPopUpForm.noSurat
            );

            let headers = {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            };

            API.post(
              "companies/sparings/compliance-points/detail",
              this.loggerFormInput,
              { headers }
            )
              .then(({ data }) => {
                Swal.fire(
                  "Logger Berhasil dibuat",
                  "Data Berhasil didaftarkan",
                  "success"
                );
                // this.getLogerRegistered(this.loggerSensorIndex.id)

                this.getListDataTitikPenaatanForCreateLogger();

                this.loggerFormInput = new FormData();
                this.loggerSensorPopUpForm = {
                  brand: "",
                  model: "",
                  sn: "",
                  mac: "",
                  lulusUji: "",
                  infoAlat: "",
                  namaPenyedia: "",
                  noSurat: "",
                  suratketerangan: "",
                };

                this.loggerFormInput = new FormData();

                this.showFormLoggerSensorFormModal = false;

                this.fileSuratKeteranganLulusUji++;
                this.fileHasilKalibrasiSaatAwalPemasanganAlat++;
                this.fileHasilKalibrasiAlatRutin++;
                this.fileHasilUjiKinerjaDariLabotarium++;
                this.fileSuratDukunganBrandSensor++;
                this.fileSuratPernyataan++;
                this.fileKontakKerjaSama++;
              })
              .catch((err) => {
                console.log(err);
                Swal.fire("Pendaftaran Gagal", "terjadi kesalahan", "error");
              });
          }
        });
      }

      console.log("RUN");

      // console.log("add Logger Sensor");

      // console.log(this.loggerSensorIndex);
      // let indexToEdit = this.form.teknis.spotList.findIndex(
      //   (tableRow) => tableRow.spotName === this.loggerSensorIndex.spotName
      // );

      // persyaratan teknis
      // this.form.teknis.spotList[indexToEdit].lulusUji = this.loggerSensorPopUpForm.lulusUji
      // this.form.teknis.spotList[indexToEdit].infoAlat = this.loggerSensorPopUpForm.infoAlat
      // this.form.teknis.spotList[indexToEdit].namaPenyedia = this.loggerSensorPopUpForm.namaPenyedia
      // this.form.teknis.spotList[indexToEdit].noSurat = this.loggerSensorPopUpForm.noSurat

      // logger
      // this.form.teknis.spotList[indexToEdit].brand = this.loggerSensorPopUpForm.brand
      // this.form.teknis.spotList[indexToEdit].model = this.loggerSensorPopUpForm.model
      // this.form.teknis.spotList[indexToEdit].sn = this.loggerSensorPopUpForm.sn
      // this.form.teknis.spotList[indexToEdit].mac = this.loggerSensorPopUpForm.mac

      // this.form.teknis.spotList[indexToEdit].sensor = [
      //   {
      //     "name": "PH",
      //     "brand": this.form.sensor[0].brand,
      //     "probe": this.form.sensor[0].probe,
      //     "sensor_range_min": +this.form.sensor[0].min,
      //     "sensor_range_max": +this.form.sensor[0].max,
      //     "quality_standard_min": +this.form.sensor[0].minMeasure,
      //     "quality_standard_max": +this.form.sensor[0].maxMeasure,
      //     "measurement_method": this.form.sensor[0].metodePengukuran,
      //     "calibration_schedule": this.form.sensor[0].jadwalKalibrasi,
      //     "calibration_date": this.form.sensor[0].tanggalKalibrasi,
      //     "calibration_method": this.form.sensor[0].metodeKalibrasi
      //   },
      //   {
      //     "name": "COD",
      //     "brand": this.form.sensor[1].brand,
      //     "probe": this.form.sensor[1].probe,
      //     "sensor_range_min": +this.form.sensor[1].min,
      //     "sensor_range_max": +this.form.sensor[1].max,
      //     "quality_standard_min": +this.form.sensor[1].minMeasure,
      //     "quality_standard_max": +this.form.sensor[1].maxMeasure,
      //     "measurement_method": this.form.sensor[1].metodePengukuran,
      //     "calibration_schedule": this.form.sensor[1].jadwalKalibrasi,
      //     "calibration_date": this.form.sensor[1].tanggalKalibrasi,
      //     "calibration_method": this.form.sensor[1].metodeKalibrasi
      //   },
      //   {
      //     "name": "TSS",
      //     "brand": this.form.sensor[2].brand,
      //     "probe": this.form.sensor[2].probe,
      //     "sensor_range_min": +this.form.sensor[2].min,
      //     "sensor_range_max": +this.form.sensor[2].max,
      //     "quality_standard_min": +this.form.sensor[2].minMeasure,
      //     "quality_standard_max": +this.form.sensor[2].maxMeasure,
      //     "measurement_method": this.form.sensor[2].metodePengukuran,
      //     "calibration_schedule": this.form.sensor[2].jadwalKalibrasi,
      //     "calibration_date": this.form.sensor[2].tanggalKalibrasi,
      //     "calibration_method": this.form.sensor[2].metodeKalibrasi
      //   },
      //   {
      //     "name": "NH3N",
      //     "brand": this.form.sensor[3].brand,
      //     "probe": this.form.sensor[3].probe,
      //     "sensor_range_min": +this.form.sensor[3].min,
      //     "sensor_range_max": +this.form.sensor[3].max,
      //     "quality_standard_min": +this.form.sensor[3].minMeasure,
      //     "quality_standard_max": +this.form.sensor[3].maxMeasure,
      //     "measurement_method": this.form.sensor[3].metodePengukuran,
      //     "calibration_schedule": this.form.sensor[3].jadwalKalibrasi,
      //     "calibration_date": this.form.sensor[3].tanggalKalibrasi,
      //     "calibration_method": this.form.sensor[3].metodeKalibrasi
      //   },
      //   {
      //     "name": "DEBIT",
      //     "brand": this.form.sensor[4].brand,
      //     "probe": this.form.sensor[4].probe,
      //     "sensor_range_min": +this.form.sensor[4].min,
      //     "sensor_range_max": +this.form.sensor[4].max,
      //     "quality_standard_min": +this.form.sensor[4].minMeasure,
      //     "quality_standard_max": +this.form.sensor[4].maxMeasure,
      //     "measurement_method": this.form.sensor[4].metodePengukuran,
      //     "calibration_schedule": this.form.sensor[4].jadwalKalibrasi,
      //     "calibration_date": this.form.sensor[4].tanggalKalibrasi,
      //     "calibration_method": this.form.sensor[4].metodeKalibrasi
      //   },
      // ]

      // this.form.sensor = [
      //     {
      //       name: "pH",
      //       label: "pH",
      //       active: false,
      //       brand: null,
      //       probe: null,
      //       brosurFile: {
      //         path: "",
      //         upload: 0,
      //         progress: 0,
      //       },
      //       min: null,
      //       max: null,
      //       minMeasure: null,
      //       maxMeasure: null,
      //       metodePengukuran: null,
      //       jadwalKalibrasi: null,
      //       tanggalKalibrasi: new Date(),
      //       metodeKalibrasi: null,
      //     },
      //     {
      //       name: "COD",
      //       label: "COD",
      //       active: false,
      //       brand: null,
      //       probe: null,
      //       brosurFile: {
      //         path: "",
      //         upload: 0,
      //         progress: 0,
      //       },
      //       min: null,
      //       max: null,
      //       minMeasure: null,
      //       maxMeasure: null,
      //       metodePengukuran: null,
      //       jadwalKalibrasi: null,
      //       tanggalKalibrasi: new Date(),
      //       metodeKalibrasi: null,
      //     },
      //     {
      //       name: "TSS",
      //       label: "TSS",
      //       active: false,
      //       brand: null,
      //       probe: null,
      //       brosurFile: {
      //         path: "",
      //         upload: 0,
      //         progress: 0,
      //       },
      //       min: null,
      //       max: null,
      //       minMeasure: null,
      //       maxMeasure: null,
      //       metodePengukuran: null,
      //       jadwalKalibrasi: null,
      //       tanggalKalibrasi: new Date(),
      //       metodeKalibrasi: null,
      //     },
      //     {
      //       name: "NH3N",
      //       label: "NH3N",
      //       active: false,
      //       brand: null,
      //       probe: null,
      //       brosurFile: {
      //         path: "",
      //         upload: 0,
      //         progress: 0,
      //       },
      //       min: null,
      //       max: null,
      //       minMeasure: null,
      //       maxMeasure: null,
      //       metodePengukuran: null,
      //       jadwalKalibrasi: null,
      //       tanggalKalibrasi: new Date(),
      //       metodeKalibrasi: null,
      //     },
      //     {
      //       name: "Debit",
      //       label: "Debit",
      //       active: false,
      //       brand: null,
      //       probe: null,
      //       brosurFile: {
      //         path: "",
      //         upload: 0,
      //         progress: 0,
      //       },
      //       min: null,
      //       max: null,
      //       minMeasure: null,
      //       maxMeasure: null,
      //       metodePengukuran: null,
      //       jadwalKalibrasi: null,
      //       tanggalKalibrasi: new Date(),
      //       metodeKalibrasi: null,
      //     },
      // ]

      // this.loggerSensorPopUpForm.lulusUji = ""
      // this.loggerSensorPopUpForm.infoAlat = ""
      // this.loggerSensorPopUpForm.namaPenyedia = ""
      // this.loggerSensorPopUpForm.noSurat = ""

      // this.loggerSensorPopUpForm.brand = ""
      // this.loggerSensorPopUpForm.model = ""
      // this.loggerSensorPopUpForm.sn = ""
      // this.loggerSensorPopUpForm.mac = ""

      // this.loggerSensorIndex = {}

      // console.log("END RUN");
    },
    tutupDataLoggerSensor() {
      this.testingbro = "";
      this.showFormLoggerSensorFormModal = false;
      this.loggerFormInput = new FormData();

      this.fileSuratKeteranganLulusUji++;
      this.fileHasilKalibrasiSaatAwalPemasanganAlat++;
      this.fileHasilKalibrasiAlatRutin++;
      this.fileHasilUjiKinerjaDariLabotarium++;
      this.fileSuratDukunganBrandSensor++;
      this.fileSuratPernyataan++;
      this.fileKontakKerjaSama++;
    },
    // old
    getFormayYear(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    showFormTitikLoggerSensorModal() {
      this.showFormLoggerSensorFormModal = true;
    },
    
    uploadFilePermissionLater(category, field) {
      this.permissionLaterForm.append(field, this.$refs[field].files[0]);
    },

    handleEditForm() {
      this.showFormEditModal = true;
    },
    showStep() {
      this.isFormSuratIzinShow = true;
    },
    showIsNormalOrWaste() {
      this.cekStatusForm = false;
      // this.isNormalOrWaste = true
      Swal.fire({
        title: "Pilih tipe pendaftaran",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Pendaftaran Normal",
        denyButtonText: `Pendaftaran khusus yang memanfaatkan air limbah`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire("Pendaftaran Normal", "", "success");
          this.showFormRegisterWaste = false;
          this.showFormRegisterNormally = true;
        } else if (result.isDenied) {
          Swal.fire("Pendaftaran khusus air limbah", "", "success");
          this.showFormRegisterNormally = false;
          this.showFormRegisterWaste = true;
        }
      });
    },
    showStatusForm() {
      this.isNormalOrWaste = false;
      this.cekStatusForm = true;
      this.showFormRegisterWaste = false;
      this.showFormRegisterNormally = false;
    },
    // ======================================================== SUBMIT ====================================================
    submitWizard() {
      console.log("register submit");
      this.showFormRegisterNormally = false;

      let result_number_compliance_point_data = [];
      for (let i = 0; i < this.form.teknis.spotList.length; i++) {
        result_number_compliance_point_data.push({
          name: this.form.teknis.spotList[i].spotName,
          latitude: this.form.teknis.spotList[i].spotLoc[0],
          longitude: this.form.teknis.spotList[i].spotLoc[1],
          waste_water_receiving_agency:
            this.form.teknis.spotList[i].penerimaAirLimbah,
          das: this.form.teknis.spotList[i].das,
          status: "AC",
          technical_requirement: {
            connectivity_test_type: this.form.teknis.spotList[i].lulusUji,
            connectivity_test_vendor: this.form.teknis.spotList[i].namaPenyedia,
            connectivity_test_number: this.form.teknis.spotList[i].noSurat,
            connectivity_test_tool_info: this.form.teknis.spotList[i].infoAlat,
          },
          data_logger: [
            {
              brand: this.form.teknis.spotList[i].brand,
              model: this.form.teknis.spotList[i].model,
              serial_number: this.form.teknis.spotList[i].sn,
              mac_address: this.form.teknis.spotList[i].mac,
              sensor: this.form.teknis.spotList[i].sensor,
            },
          ],
        });
        console.log(
          this.form.teknis.spotList[i].sensor,
          "--------------------------------->"
        );
      }

      let payload = {
        polluted_sources: [
          {
            company_document_file_uid: this.form.uid,
            waste_water_sources: this.form.teknis.wasteSource,
            type_waste_water_treatment_technology:
              this.form.teknis.processingTechnique,
            type_waste_water: this.form.teknis.wasteType,
            debit_value: this.form.teknis.debit,
            debit_unit: this.form.teknis.satuanDebit,
            permitted_production_capacity: this.form.teknis.capacityPermit,
            actual_production_capacity: this.form.teknis.capacityActual,
            frequency_wastewater_disposal: {
              type: this.form.teknis.frequenceDet,
              background: this.form.frekuensi.latarBelakang,
              detail_engineering_ipal: this.form.frekuensi.detEngIPAL,
              day: this.form.frekuensi.jam,
              month: this.form.frekuensi.hari,
              year: this.form.frekuensi.bulan,
              measurement_method: this.form.frekuensi.metUkurDebit,
            },
            number_compliance_point_num:
              result_number_compliance_point_data.length + "",
            number_compliance_point_data: result_number_compliance_point_data,
          },
        ],
      };

      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.post("web/master/registration/sparing-data", payload, { headers })
        .then(({ data }) => {
          console.log(data.registration_uid);
          Swal.fire(
            "Pendaftaran Berhasil",
            "Data Berhasil didaftarkan",
            "success"
          );
          // this.resetPayload(); --> apus
          this.showMainWizardForm = false;
          // this.$router.push("/sparing_main");

          return data.registration_uid;
        })
        .then((val) => {
          this.uploadFile(val, "sendNow");
        })
        .catch((err) => {
          console.log(err);
          Swal.fire("Pendaftaran Gagal", "terjadi kesalahan", "error");
        });
    },
    submitRegisterWaste() {
      console.log("register waste");
      this.showFormRegisterWaste = false;
    },
    submit() {
      console.log("oke");
    },
    close() {
      this.$router.push("/Home");
    },
    resetPayload() {
      this.newsensor = [];
      this.form.uid = "";
      console.log("=========Akun");
      this.form.akun.personName = "";
      this.form.akun.personPhone = "";
      this.form.akun.personMail = "";
      this.form.akun.personMailID = "";
      this.form.akun.personPassword = "";
      this.form.akun.personConfirmPassword = "";
      console.log("=========Umum");
      this.form.umum.compName = "";
      this.form.umum.compNameID = "";
      this.form.umum.compType = "";
      this.form.umum.compAddress = "";
      this.form.umum.compStreet = "";
      this.form.umum.compCity = "";
      this.form.umum.compProvince = "";
      this.form.umum.compPhone = "";
      this.form.umum.compMail = "";
      this.form.umum.nameSIUP = "";
      this.form.umum.ifTambang.status = "";
      this.form.umum.ifTambang.jenKegiatan = "";
      this.form.umum.ifTambang.kondisiInternet = "";
      console.log("==================Identifikasi Sumber Pencemaran");
      this.form.teknis.permitPerson = "";
      this.form.teknis.permitNumber = "";
      this.form.teknis.permitDatePublish = "";
      this.form.teknis.permitDateExpire = "";
      this.form.teknis.wasteSource = "";
      this.form.teknis.spotCount = "";
      this.form.teknis.spotList = "";
      this.form.teknis.processingTechnique = "";
      this.form.teknis.wasteType = "";
      this.form.teknis.debit = "";
      this.form.teknis.satuanDebit = "";
      this.form.teknis.capacityPermit = "";
      this.form.teknis.capacityActual = "";
      this.form.teknis.frequenceDet = "";
      this.form.frekuensi.jam = "";
      this.form.frekuensi.hari = "";
      this.form.frekuensi.bulan = "";
      this.form.frekuensi.metUkurDebit = "";
      this.form.frekuensi.latarBelakang = "";
      this.form.frekuensi.detEngIPAL = "";
      console.log("==================Persyaratan Teknis");
      this.form.validitas.infoAlat = "";
      this.form.validitas.lulusUji = "";
      this.form.validitas.namaPenyedia = "";
      this.form.validitas.noSurat = "";
      this.form.validitas.jadwalKalibrasi = "";
      console.log("==================Form Logger");
      // this.form.logger[0] = "";
      console.log("==================Sensor");
      this.form.sensor = "";
    },
    uploadFile(category, field) {
      this.formData.append(field, this.$refs[field].files[0]);
    },
    // new
    addSensor() {
      this.permissionList.push({
        permitFile: {
          path: "",
          upload: 0,
          progress: 0,
        },
        permitPerson: null,
        permitNumber: null,
        permitDatePublish: new Date(),
        permitDateExpire: new Date(),
        wasteSource: null,
        spotCount: 1,
        spotList: [
          {
            spotName: "",
            spotLoc: [null, null],
            penerimaAirLimbah: "",
            das: "",
          },
        ],
        processingTechnique: null,
        wasteType: null,
        debit: null,
        satuanDebit: null,
        capacityPermit: null,
        capacityActual: null,
        frequenceDet: "",
      });
      this.check();
    },
    addTitik(index) {
      this.permissionList[index].spotList.push({
        spotName: "",
        spotLoc: [null, null],
        penerimaAirLimbah: "",
        das: "",
      });

      this.permissionList[index].spotCount =
        this.permissionList[index].spotList.length;
      this.check();
    },
    removeSensor(index) {
      this.permissionList.splice(index, 1);
    },
    check() {
      console.log(this.totalCount, "INI DIA");
    },
    submitFile() {
      this.uploadFile("sendNow", "sendNow");
    },
    submitForm() {
      console.log(this.submitData());
      // this.submitFile()
      // this.submitData()
      satuanDebit;
      let payload = {
        polluted_sources: {
          // permit_issuance_officer: this.form.teknis.permitPerson,
          // waste_water_disposal_permit_number: this.form.teknis.permitNumber,
          // issue_permit_date: this.form.teknis.permitDatePublish,
          // expired_permit_date: this.form.teknis.permitDateExpire,
          // waste_water_sources: this.form.teknis.wasteSource,
          // type_waste_water_treatment_technology:
          //   this.form.teknis.processingTechnique,
          // type_waste_water: this.form.teknis.wasteType,
          // debit_value: this.form.teknis.debit,
          // debit_unit: this.form.teknis.satuanDebit,
          // permitted_production_capacity: this.form.teknis.capacityPermit,
          // actual_production_capacity: this.form.teknis.capacityActual,
          number_compliance_point_num: this.form.teknis.spotList.length,
          number_compliance_point_data: dataTitikPenataan,
          frequency_wastewater_disposal: {
            // background: this.form.frekuensi.latarBelakang,
            // detail_engineering_ipal: this.form.frekuensi.detEngIPAL,
            // day: this.form.frekuensi.jam,
            // month: this.form.frekuensi.hari,
            // year: this.form.frekuensi.bulan,
            // measurement_method: this.form.frekuensi.metUkurDebit,
          },
          data_logger: [
            {
              // brand: this.form.logger[0].brand,
              // model: this.form.logger[0].model,
              // serial_number: this.form.logger[0].sn,
              // mac_address: this.form.logger[0].mac,
              // sensor: this.form.sensor,
            },
          ],
        },
        technical_requirements: {
          // connectivity_test_type: this.form.validitas.lulusUji,
          // connectivity_test_vendor: this.form.validitas.namaPenyedia,
          // connectivity_test_number: this.form.validitas.noSurat,
          // connectivity_test_tool_info: this.form.validitas.infoAlat,
        },
        waste_use: [
          {
            name: "Debit Air",
            value: "12452",
            unit: "m3/h",
            information: "Sata",
          },
        ],
      };
    },
    uploadSensorFile(category, field) {
      this.sensorFormInput.append(field, this.$refs[field][0].files[0]);
    },
  },
  watch: {
    // wizard one
    countWizard() {
      if (this.countWizard < 1) {
        return 0;
      }
      this.permissionList.spotCount = this.countWizard;
      let array = [];
      for (let i = 0; i < this.countWizard; i++) {
        array.push({
          permitFile: {
            path: "",
            upload: 0,
            progress: 0,
          },
          permitPerson: null,
          permitNumber: null,
          permitDatePublish: new Date(),
          permitDateExpire: new Date(),
          wasteSource: null,
          spotCount: 1,
          spotList: [
            {
              spotName: "",
              spotLoc: [null, null],
              penerimaAirLimbah: "",
              das: "",
            },
          ],
          processingTechnique: null,
          wasteType: null,
          debit: null,
          satuanDebit: null,
          capacityPermit: null,
          capacityActual: null,
          frequenceDet: "",
        });
      }
      this.permissionList = array;
      this.check();
      return array.length;
    },
  },
  computed: {
    frequencyText() {
      if (this.form.teknis.isContinue) return "Terus Menerus / Kontinyu";
      else return "Tidak Rutin / Intermiten";
    },
    sensorStatus(index) {
      if (this.form.sensor[index]) return "Digunakan";
      else return "Tidak digunakan";
    },
  },
};
</script>
<style>
.el-table .el-table__header-wrapper thead th .cell {
  justify-content: flex-start;
}

.myshadow {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.step-progress .bar {
  background-color: #1b674c !important;
}

.step-button {
  display: inline-block;
  font-weight: 600;
  color: #1b674c;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.step-button-next {
  background-color: #1b674c !important;
  color: #fff;
}

.step-pills .step-item.active {
  border: 1px solid #1b674c !important;
}

.step-pills .step-item {
  margin-right: 5px;
}

.step-item {
  position: relative;
  width: 200px;
  height: 100px;
}

.tabLabel {
  position: absolute;
  bottom: 10px;
  left: 10px;
  line-height: 17px;
}

.popup-modal-background {
  background: #fff;
}

.mdl-contentpopup {
  background: #fff;
  overflow-y: auto;
  max-height: 90%;
}
</style>
